import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { SkillService } from "./skill.service";
import { Observable} from "rxjs";
import { University } from "../models/university";

@Injectable({
  providedIn: "root",
})
export class RealMoodleService {
  constructor(
    private http: HttpClient,
    private authSerivce: AuthService,
    private router: Router,
    private toastController: ToastController,
    public skillService: SkillService
  ) {
    if (
      !localStorage.getItem("filesItemid") ||
      typeof localStorage.getItem("filesItemid") != "undefined"
    ) {
      this.itemid = "0";
    } else {
      this.itemid = localStorage.getItem("filesItemid");
    }
  }

  get currentUniversity() {
    const university = JSON.parse(localStorage.getItem("item"));
    return university;
  }

  get currentUser() {
    const user = this.authSerivce.loadUser("user");
    return user;
  }

  get userToken() {
    const user = this.authSerivce.loadUser("user");
    return user.moodleToken;
  }
  public showSplash = false;
  public itemid: string;
  public saveditemid: string;
  public baseAdminToken = "8a0f6a4af1e87a0337a9b10c7eae9802";
  public BASE_URL = "https://moodle.european-crossborder-skills.eu";
  public indexCurrentFile: number;
  public showProgressBar = false;

  public badges: any;

  public getAllStrings: object;

  public allUniversities: Array<University>;

  public univDevices: Array<any>;

  public devices: any;

  public useCases: any;

  public onlineTextContent: any;

  public linkedSkills: any;

  /**
   * Get list of universities
   */

  getAllUniversities() {
    return new Promise((resolve, reject) => {
      if (this.allUniversities) {
        resolve(this.allUniversities);
      } else {
        this.http
          .get(
            `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
            moodlewsrestformat=json&wsfunction=local_webservices_universities_init`
          )
          .subscribe((data: any) => {
            this.allUniversities = data;
            resolve(this.allUniversities);
          });
      }
    });
  }

  gdprRequestId(username) {
    const formData = new FormData();
    formData.append("wstoken", this.baseAdminToken);
    formData.append("username[0]", username);

    return this.http.post(
            `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
            moodlewsrestformat=json&wsfunction=local_webservices_approve_gdpr`,
            formData
          );
  }


  approveGDPR(token) {
    const formData = new FormData();
    formData.append("wstoken", this.baseAdminToken);
    formData.append("requestid", String(1));

    console.log('token', token)

    return this.http.post(
            `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
            moodlewsrestformat=json&wsfunction=tool_dataprivacy_approve_data_request`,
            formData
          );
  }

  /**
   * Get device of an university
   * @param univId University id
   */

  getDevices(univId: number) {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(this.univDevices)) {
        this.univDevices = new Array();
      }
      if (!Array.isArray(this.univDevices[univId])) {
        this.http
          .get(
            `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
            moodlewsrestformat=json&wsfunction=local_webservices_univ_devices_init&universityid[0]=${univId}`
          )
          .subscribe((data: any) => {
            this.univDevices[univId] = data;
            resolve(data);
          });
      } else {
        resolve(this.univDevices[univId]);
      }
    });
  }

  /**
   * Notify teacher abut the new submit
   * @param univId University id
   * @param userid  id
   */
  notifyTeacherAboutSubmit(univId: number, userid) {
    const formData = new FormData();
    formData.append("wstoken", this.baseAdminToken);
    formData.append("moodlewsrestformat", "json");
    formData.append("wsfunction", "local_webservices_notify_teacher");
    formData.append("universityid[0]", String(univId));
    formData.append("userid[0]", String(userid));
    return this.http.post(
            `${this.BASE_URL}/webservice/rest/server.php`, formData
          );
  }

  /**
   * Get device data
   *
   * @param  deviceId Programme id
   */

  getDeviceById(deviceId: number) {
    return new Promise((resolve, reject) => {
      if (!this.devices) {
        this.devices = new Array();
        this.http
          .get(
            `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}` +
              "&moodlewsrestformat=json&wsfunction=core_course_get_courses"
          )
          .subscribe((data: any) => {
            // build devices Array.
            data.forEach((element) => {
              // customize custom fields
              const mycustomfields = new Array();
              element.customfields.forEach((cf) => {
                if (this.displayCustomField(cf)) {
                  mycustomfields.push(cf);
                }
              });
              element.customfields = mycustomfields;
              element.display = false;
              this.devices[element.id] = element;
            });
            const device = this.devices.find(
              (item) => item && item.id == deviceId
            );

            resolve(device);
          });
      } else {
        const device = this.devices.find((item) => item.id == deviceId);
        resolve(device);
      }
    });
  }

  /**
   * Display only "Name", "Name in English" and "Brief description"
   * in device "View details" section
   * @param cf customfield
   */
  displayCustomField(cf) {
    return (
      cf.shortname == "device_name" ||
      cf.shortname == "device_name_en" ||
      cf.shortname == "device_short_description"
    );
  }

  /**
   * Get one badge
   *
   * @param  badgeId Badge id
   */

  getBadgesById(badgeId: number) {
    return new Promise((resolve, reject) => {
      if (!this.badges) {
        this.http
          .get(
            `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}` +
              "&moodlewsrestformat=json&wsfunction=local_webservices_badge_init"
          )
          .subscribe((data: any) => {
            this.badges = data;
            const badge = this.badges.find((item) => item.id == badgeId);
            resolve(badge);
          });
      } else {
        const badge = this.badges.find((item) => item.id == badgeId);
        resolve(badge);
      }
    });
  }

  
  /**
   * Get all skills
   *
   */

  getAllSkills() {
    return new Promise((resolve, reject) => {
      if (!this.badges) {
        this.http
          .get(
            `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}` +
              "&moodlewsrestformat=json&wsfunction=local_webservices_badge_init"
          )
          .subscribe((data: any) => {
            this.badges = data;
            resolve(this.badges);
          });
      } else {
        resolve(this.badges);
      }
    });
  }

  getString(value, stringid) {
    return this.http
      .get(`${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}
      &moodlewsrestformat=json&wsfunction=core_get_strings&strings[0][stringid]=${stringid}&
      strings[0][component]=theme_ecbs&strings[0][lang]=en&strings[0][stringparams][0][value]=${value}
    `);
  }

  /**
   * Get value of getAllStrings Object
   *
   * @param  key Object key
   */

  getStringValue(key: string) {
    return this.getAllStrings[key];
  }

  /**
   * Get all or one string from Moodle in order to make translation
   *
   * @param keySearch Search key of all Strings object
   */

  getStrings(keySearch: string): any {
    return new Promise((resolve, reject) => {
      if (!this.getAllStrings) {
        this.http
          .get(
            `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
            moodlewsrestformat=json&wsfunction=local_webservices_translate`
          )
          .subscribe((data: any) => {
            this.getAllStrings = data;

            resolve(this.getAllStrings);
          });
      } else {
        let getOneString: object;

        for (const [key, value] of Object.entries(this.getAllStrings)) {
          if (key == keySearch) {
            getOneString = value;
          }
        }
        resolve(getOneString);
      }
    });
  }

  /**
   * Reset User password
   */

  resetPasswordDemand(userEmail) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=local_webservices_reset_password&wstoken=${this.baseAdminToken}&
      userEmail[0]=${userEmail}`
    );
  }

  /**
   * Add or remove a skill
   *
   * @param skillName The name of the skill
   * @param selection Remove or add a skill in cart
   * @param token User token defined in Moodle
   */

  skillsToggle(skillName: string, selection: string, token: string) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=local_webservices_skills_init&usertoken[0]=${token}&
      skill_name[0]=${skillName}&selected[0]=${selection}`
    );
  }


  /**
   * University choice between Open Badges and/or Certificats
   *
   * @param univId ID university
   */

  universityChoice(univId: number) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=local_webservices_certifications_openbadges&universityid[0]=${univId}`
    );
  }

  /**
   * Get all skills already added
   *
   * @param token User token defined in Moodle
   */
  getSkillsAdded(token: string) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=local_webservices_skills_display_init&usertoken[0]=${token}`
    );
  }

  /**
   * Remove all user skills from cart
   *
   * @param token User token defined in Moodle
   */

  removeAllSkillsChosen(token: string) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=local_webservices_multiplatform_skills_state_init&usertoken[0]=${token}`
    );
  }

  /**
   * Download all badges
   *
   * @param userid User id
   * @param downloadAll Determine if the demand concerns one badge or several badges
   * @param badgeid Badge id
   */
  downloadBadges(userid: number, downloadAll: boolean, badgeid: string = null) {
    const formData = new FormData();
    formData.append("wstoken", this.userToken);
    formData.append("userid[0]", String(userid));
    formData.append("all[0]", JSON.stringify(downloadAll));
    if (badgeid != null) {
      formData.append("badgeid[0]", badgeid);
    }
    return this.http.post(
      `${this.BASE_URL}/webservice/rest/server.php?wsfunction=local_webservices_download_badges&
      moodlewsrestformat=json&wstoken=${this.baseAdminToken}`,
      formData,
      { responseType: "blob" }
    );
  }

  /**
   * Get assignment id of the course concerned
   *
   * @param courseid Course id
   */

  getAssignmentId(courseid: number) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wsfunction=mod_assign_get_assignments&
      moodlewsrestformat=json&wstoken=${this.userToken}&courseids[0]=${courseid}`
    );
  }

  /**
   * Get use cases
   *
   */

  getUseCases() {
    return new Promise((resolve, reject) => {
      if (!this.useCases) {
        this.http
          .get(
            `${this.BASE_URL}/webservice/rest/server.php?wsfunction=local_webservices_use_cases_skills&
            moodlewsrestformat=json&wstoken=${this.baseAdminToken}`
          )
          .subscribe((data: any) => {
            this.useCases = data;
            resolve(this.useCases);
          });
      } else {
        resolve(this.useCases);
      }
    });
  }

  /**
   * User acquired badges
   *
   * @param  userid User id
   */

  public getUserAcquiredBadges(userid: number) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?moodlewsrestformat=json&
      wstoken=${this.userToken}&wsfunction=local_webservices_badges_issued&userid[0]=${userid}`
    );
  }

  /**
   * Send an email to the university if the searched device is not in the list
   *
   * @param  universityEmail The university email
   * @param  emailUser User email
   * @param  content Content to send
   */

  public sendDeviceEmail(
    universityEmail: string,
    emailUser: string,
    content: any
  ) {
    const formData = new FormData();
    formData.append("moodlewsrestformat", "json");
    formData.append("wstoken", this.baseAdminToken);
    formData.append("universityEmail[0]", universityEmail);
    formData.append("emailUser[0]", emailUser);
    formData.append("content[0]", content);

    return this.http.post(
      `${this.BASE_URL}/webservice/rest/server.php?wsfunction=local_webservices_init_send_email`,
      formData
    );
  }

  /**
   * Update user subscription state to notification
   *
   * @param  userid User id
   * @param  subscriptionVerification User subscription state (subscribed or unsubscribed)
   */

  public manageOneSignalUserId(
    userid: number,
    subscriptionVerification: boolean
  ) {
    const formData = new FormData();
    formData.append("moodlewsrestformat", "json");
    formData.append("wstoken", this.baseAdminToken);
    formData.append("userid[0]", String(userid));

    formData.append(
      "subscription[0]",
      JSON.stringify(subscriptionVerification)
    );
    return this.http.post(
      `${this.BASE_URL}/webservice/rest/server.php?wsfunction=local_webservices_init_opensignal_playerid`,
      formData
    );
  }

  /**
   * Get or Updade moodle text editor
   *
   * @param  userid User id
   */

  public submissionOnlineText(userid: number) {
    return new Promise((resolve, reject) => {
      if (!this.onlineTextContent) {
        const formData = new FormData();
        formData.append("moodlewsrestformat", "json");
        formData.append("wstoken", this.baseAdminToken);
        formData.append("userid[0]", String(userid));

        this.http
          .post(
            `${this.BASE_URL}/webservice/rest/server.php?wsfunction=local_webservices_submission_online_text_values
          `,
            formData
          )
          .subscribe((data) => {
            this.onlineTextContent = data;
            resolve(this.onlineTextContent);
          });
      } else {
        resolve(this.onlineTextContent);
      }
    });
  }

  /**
   * Get all subscribed users for notification
   *
   * @param  userid User id
   */

  public getSubscribedUsers(userid: number) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=local_webservices_init_onesignal&userid[0]=${userid}`
    );
  }

  /**
   * Get the user's university gdpr and/or update gdpr version in user's personal field
   *
   * @param  universityid University id
   * @param  useridThe User id
   * @param  action  Take two values Write and Update. Write for creating gdpr version and update for updating gdpr version
   *
   */

  public getUniversityGDPR(
    universityid: number,
    userid: number,
    action?: string
  ) {
    const formData = new FormData();
    formData.append("moodlewsrestformat", "json");
    formData.append("wstoken", this.baseAdminToken);
    formData.append("universityid[0]", String(universityid));
    if (userid != null) {
      formData.append("userid[0]", String(userid));
    }
    if (action) {
      formData.append("action[0]", action);
    }

    return this.http.post(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=local_webservices_universities_gdpr`,
      formData
    );
  }

  /**
   * Email sending function
   *
   * @param  request Action
   * @param  userid User id
   */

  public send_email(request: string, userid: number) {
    const formData = new FormData();
    formData.append("moodlewsrestformat", "json");
    formData.append("wstoken", this.baseAdminToken);
    formData.append("request[0]", request);
    formData.append("userid[0]", String(userid));
    return this.http.post(
      `${this.BASE_URL}/webservice/rest/server.php?wsfunction=local_webservices_send_email`,
      formData
    );
  }

  /**
   * Change status demand(draft area or ready to be graded)
   *
   * @param userid User id
   */

  public checkSubmissionState(userid: number) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=local_webservices_submissionstatus&userid[0]=${userid}`
    );
  }

  /**
   * Display skills mapping in PDF
   *
   * @param skills User skills in cart or/and awarded skills
   */

  public showUserCertificateSkills(skills: Array<object>) {
    const formData = new FormData();
    formData.append("moodlewsrestformat", "json");
    formData.append("wstoken", this.baseAdminToken);
    formData.append("skills[0]", JSON.stringify(skills));
    return this.http.post(
      `${this.BASE_URL}/webservice/rest/server.php?wsfunction=local_webservices_pdf_ecbs_certificate`,
      formData,
      { responseType: "blob" }
    );
  }

  /**
   * Display all linked skills
   *
   */

  public display_linked_skills() {
    return new Promise((resolve, reject) => {
      if (!this.linkedSkills) {
        const formData = new FormData();
        formData.append("moodlewsrestformat", "json");
        formData.append("wstoken", this.baseAdminToken);
        this.http
          .post(
            `${this.BASE_URL}/webservice/rest/server.php?wsfunction=local_webservices_init_linked_skills`,
            formData
          )
          .subscribe((data: any) => {
            this.linkedSkills = data;
            resolve(this.linkedSkills);
          });
      } else {
        resolve(this.linkedSkills);
      }
    });
  }

  /**
   * Create new Mahara User
   *
   * @param  username User username
   * @param  email User email
   * @param  lastName User last name
   * @param  firstName User first name
   * @param  password User password
   */

  public createNewUser(
    username: string,
    email: string,
    lastName: string,
    firstName: string,
    password: string
  ) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=core_user_create_users&users[0][username]=${username}&
      users[0][email]=${email}&users[0][lastname]=${lastName}&users[0][firstname]=${firstName}&
      users[0][password]=${password}`
    );
  }

  /**
   * Send final demand to Moodle
   *
   * @param form Form model
   */

  public submitAssignment(form: any) {
    const formData = new FormData();
    formData.append("moodlewsrestformat", "json");
    formData.append("wstoken", this.userToken);
    formData.append("assignmentid", form.assignmentid.toString());
    formData.append("plugindata[onlinetext_editor][text]", form.pluginText);
    formData.append(
      "plugindata[onlinetext_editor][format]",
      form.pluginTextFormat.toString()
    );
    formData.append(
      "plugindata[onlinetext_editor][itemid]",
      form.pluginItemId.toString()
    );
    if (this.itemid == "undefined") {
      this.itemid = undefined;
    }

    if (typeof this.saveditemid != "undefined") {
      formData.append("plugindata[files_filemanager]", this.saveditemid);
    } else if (typeof this.itemid != "undefined") {
      formData.append("plugindata[files_filemanager]", this.itemid);
    } else {
      this.itemid = "0";
      formData.append("plugindata[files_filemanager]", this.itemid);
    }

    return this.http.post(
      `${this.BASE_URL}/webservice/rest/server.php?wsfunction=mod_assign_save_submission`,
      formData
    );
  }

  /**
   * Modify status demand to 'draft area'
   */

  public draft_submission_status() {
    return this.http
      .get(`${this.BASE_URL}/webservice/rest/server.php?wsfunction=mod_assign_revert_submissions_to_draft&moodlewsrestformat=json&
      wstoken=${this.baseAdminToken}&assignmentid=${this.currentUser.course_assignment_id}&userids[0]=${this.currentUser.auth.id}`);
  }

  /**
   * Change status demand to 'Ready to be graded'
   *
   * @param  assignmentid Assignment id of the university
   */

  public grading_submission_status(assignmentid: number) {
    return this.http
      .get(`${this.BASE_URL}/webservice/rest/server.php?wsfunction=mod_assign_submit_for_grading&moodlewsrestformat=json&
      wstoken=${this.userToken}&assignmentid=${assignmentid}&acceptsubmissionstatement=0`);
  }

  /**
   * Get user submission status
   *
   * @param  userid User id
   * @param  assignid Assignment if of the university
   */

  public submission_status(userid: number, assignmentid: number) {
    return this.http
      .get(`${this.BASE_URL}/webservice/rest/server.php?wsfunction=mod_assign_get_submission_status&moodlewsrestformat=json&
      wstoken=${this.baseAdminToken}&assignid=${assignmentid}&userid=${userid}`);
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      color: "favorite",
    });
    toast.present();
  }
  

  /**
   * Login
   *
   * @param email User email
   * @param password User password
   */

  public loginUser(email: string, password: string) {
    return this.http.get(
      `${this.BASE_URL}/login/token.php?username=${email}&password=${password}&service=ecbsapp`
    );
  }

  /**
   * Manual enrolment in course
   *
   * @param  field Field to be retrieved
   * @param  match Value that must match with field
   */

  public getUserInfos(field: string, match: any, tokenType: string) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?moodlewsrestformat=json&
      wstoken=${tokenType}&wsfunction=core_user_get_users_by_field&field=${field}&values[0]=${match}`
    );
  }

  /**
   * Manual enrolment in course
   *
   * @param  courseid University id
   * @param  userid User id
   */

  public enrolCourse(courseid: string | number, userid: number) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=enrol_manual_enrol_users&enrolments[0][courseid]=${courseid}&
      enrolments[0][roleid]=5&enrolments[0][userid]=${userid}`
    );
  }


  /**
   * Update user account password
   *
   * @param  userid User id
   * @param  password User password
   */

  public updatePassword(userid: number, password: string) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=core_user_update_users&
      users[0][password]=${password}&users[0][id]=${userid}`
    );
  }

  /**
   * Manual unenrolment from a course
   *
   * @param courseid University id
   * @param userid User id
   */

  public unEnrolCourse(courseid: string, userid: number) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=enrol_manual_unenrol_users&enrolments[0][courseid]=${courseid}&
      enrolments[0][roleid]=5&enrolments[0][userid]=${userid}`
    );
  }

  /**
   * Get user all enroled courses
   *
   * @param userid User id
   */

  public getUserCourses(userid: number) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.userToken}&
      moodlewsrestformat=json&wsfunction=core_enrol_get_users_courses&userid=${userid}`
    );
  }

  /**
   * Create new Mahara user
   *
   * @param  userid User id
   */

  public createMaharaNewUser(userid: number) {
    return this.http.get(
      `${this.BASE_URL}/webservice/rest/server.php?wstoken=${this.baseAdminToken}&
      moodlewsrestformat=json&wsfunction=local_webservices_new_user&userid[0]=${userid}`
    );
  }

  /**
   * Manage Moodle unexpected errors
   *
   */

  public manageUnexpectedErrors() {
    this.skillService.opensweetalert(
      "An unexpected error occurred. Please try again later",
      "question",
      null,
      "The Internet ?"
    );
    this.router.navigateByUrl("/tabs/list");
  }

  /**
   * Download files to Moodle
   *
   * @param  userInfo Various informations about user
   * @param  fileArray Demand concerning evidences
   *
   */

  public recursiveUpload(fileArray: Array<File[]>, userInfo: any) {
    if (!this.indexCurrentFile) {
      this.indexCurrentFile = 0;
    }

    const currentFile = fileArray[this.indexCurrentFile];

    // The evidence has one file
    if (this.indexCurrentFile == 0) {
      this.sendFile(currentFile, userInfo).subscribe(
        (data: any) => {
          // this.itemid = data.itemid;
          this.itemid = data.itemid;
          localStorage.setItem("filesItemid", data.itemid);
          this.indexCurrentFile += 1;

          this.recursiveUpload(fileArray, userInfo);
        },
        (err) => {
          this.manageUnexpectedErrors();
        }
      );
    } else if (fileArray.length > 1) {
      // The evidence contains more than one file

      if (this.indexCurrentFile < fileArray.length - 1) {
        // next files
        this.sendFile(currentFile, userInfo).subscribe(
          (data: any) => {
            this.indexCurrentFile += 1;
            this.itemid = data.itemid;
            this.recursiveUpload(fileArray, userInfo);
          },
          (err) => {
            this.manageUnexpectedErrors();
          }
        );
      } else {
        // The last file
        this.sendFile(currentFile, userInfo).subscribe(
          (data: any) => {
            this.indexCurrentFile = null;
            this.saveDemandIntoDraft();
          },
          (err) => {
            this.manageUnexpectedErrors();
          }
        );
      }
    } else {
      this.indexCurrentFile = null;
      this.saveDemandIntoDraft();
    }
  }

  /**
   * Order certificate demand by skill name
   */

  public formatCertificateDemand(arr) {
    const obj = arr.reduce((res, curr) => {
      if (res[curr.competency]) {
        res[curr.competency].push(curr);
      } else {
        Object.assign(res, { [curr.competency]: [curr] });
      }
      return res;
    }, {});
    return obj;
  }

  /**
   * Generate new demand id
   */

  generateDemandId() {
    return Math.random().toString(36).substring(7);
  }

  /**
   * Update skillSerevice state
   *
   * @param skillService SkillService service
   */

  public setSkillService(skillService: any) {
    this.skillService = skillService;
  }

  /**
   *  Save user certificate demand in draft area
   *
   * @param id File id to be removed
   */

  public saveDemandIntoDraft(id?: string) {
    if (typeof id == "string") {
      // Remove current file and update
      this.submissionOnlineText(this.skillService.user.auth.id).then(
        (data: any) => {
          for (const index in data.existingValues) {
            if (data.existingValues[index]) {
              data.existingValues[index] = data.existingValues[index].filter((existingValue) => {
                return existingValue.id != id;
              });
            }
          }

          this.saveCertificateDemand(
            data.existingValues,
            this.skillService.moodleSkillsToggle,
            id
          );
        }
      );
    } else {
    // Add or update skill object
      let demandId;
      const moodleJSONArr = new Array();
      let currentItemId;
      if (this.saveditemid != null) {
        currentItemId = this.saveditemid;
      } else {
        currentItemId = this.itemid;
      }


      // Stop code execution if if there are not a file or a skill in the demand
      demandId = this.generateDemandId();
      if (
        this.skillService.moodleSkillsToggle._mdl_skills.length == 0 ||
        this.skillService.moodleSkillsToggle.files.length == 0
      ) {

        this.getStrings("alert:error_request_send").then((data: string) => {
          this.skillService.opensweetalert(data, "error");
        });
        return;
      }

      for (
        var i = 0;
        i < this.skillService.moodleSkillsToggle._mdl_skills.length;
        ++i
      ) {
        for (
          var j = 0;
          j < this.skillService.moodleSkillsToggle.files.length;
          ++j
        ) {
          const fileObject = this.skillService.moodleSkillsToggle.files[j];

          for (let k = 0; k < fileObject.skillsAssociatedChosen.length; k++ ) {
            moodleJSONArr.push({
              id: fileObject.id,
              competency: fileObject.skillsAssociatedChosen[k],
              fileid: fileObject.id,
              name: fileObject.name,
              message_length: fileObject.message_length,
              comment: fileObject.message,
              skillsAssociated: fileObject.skillsAssociated,
              skillsAssociatedChosen: fileObject.skillsAssociatedChosen,
              urlValues: fileObject.urlValues,
              itemid: currentItemId,
              demandId,
              date: fileObject.date,
              status: "submitted",
            });
          }
        }
        break;
      }

      this.submissionOnlineText(this.skillService.user.auth.id).then(
          (onlineText: any) => {
            if (onlineText.exception || onlineText.warningcode) {
              this.getStrings("alert:error_request_send").then(
                (data: string) => {
                  this.skillService.opensweetalert(data, "error");
                }
              );

              this.showSplash = false;
              this.showProgressBar = false;
              return;
            }
            let onlineTextFormat;

            if (onlineText.recordExist == true) {
              const keyLength = Object.keys(onlineText.existingValues).length;

              if (keyLength > 0) {
                // Skill object already exist in the demand
                for (const index in onlineText.existingValues) {
                  if (onlineText.existingValues[index]) {
                    onlineText.existingValues[index] = onlineText.existingValues[index].filter(
                      (item) => {
                        return (
                          item.status != "submitted" && item.status != "renew"
                        );
                      }
                    );

                    if (onlineText.existingValues[index].length == 0) {
                      delete onlineText.existingValues[index];
                    }

                    moodleJSONArr.forEach((mdl) => {
                      if (
                        onlineText.existingValues[mdl.competency] &&
                        mdl.competency == index
                      ) {
                        // Existing key so push into
                        const pos = onlineText.existingValues[
                          mdl.competency
                        ].findIndex((item) => item.status == "refused");
                        if (pos > -1) {
                          mdl.status = "renew";
                        }
                        onlineText.existingValues[mdl.competency].push(mdl);
                        const formatFormValues = this.formatCertificateDemand(
                          moodleJSONArr
                        );
                        onlineTextFormat = Object.assign(
                          formatFormValues,
                          onlineText.existingValues
                        );
                      } else if (
                        typeof onlineText.existingValues[mdl.competency] ==
                        "undefined"
                      ) {
                        // Not existing key so format data
                        const formatFormValues = this.formatCertificateDemand(
                          moodleJSONArr
                        );
                        onlineTextFormat = Object.assign(
                          formatFormValues,
                          onlineText.existingValues
                        );
                      }
                    });
                  }
                }
              } else {
               // Skill object not exist in the demand
                onlineTextFormat = this.formatCertificateDemand(moodleJSONArr);
              }

              this.saveCertificateDemand(onlineTextFormat);
            } else {
              // First demand
              onlineTextFormat = this.formatCertificateDemand(moodleJSONArr);
              this.saveCertificateDemand(onlineTextFormat);
            }
          }
        );
    }
  }

  /**
   * Send the certification demand to Moodle
   *
   * @param FormValues Form's values
   * @param  moodleObject Skills to be filtered if an element is removed
   * @param  fileid Element with fileid to be removed
   */

  saveCertificateDemand(
    formValues: Array<any>,
    moodleObject?: any,
    fileid?: string
  ) {
    const finalStringify = JSON.stringify(formValues);

    // Moodle webservice object model
    const createAssignmentModel = {
      assignmentid:
        typeof this.currentUser.course_assignment_id == "undefined"
          ? 9
          : this.currentUser.course_assignment_id,
      pluginText: finalStringify,
      pluginTextFormat: 1,
      pluginItemId: 0,
    };

    this.submitAssignment(createAssignmentModel).subscribe((sent: any) => {
      if (sent.exception || sent.warningcode) {
        // Exception
        this.getStrings("alert:error_request_send").then((data: string) => {
          this.skillService.opensweetalert(data, "error");
        });

        this.skillService.moodleSkillsToggle._files.length = 0;
        this.showSplash = false;
        this.showProgressBar = false;
      } else if (Array.isArray(sent)) {
        if (sent.length > 0) {
           // Exception
          this.getStrings("alert:error_request_send").then((data: string) => {
            this.skillService.opensweetalert(data, "error");
          });

          this.showSplash = false;
          this.showProgressBar = false;
          this.skillService.moodleSkillsToggle._files.length = 0;
          return;
        } else {
          // Successful
          if (!fileid) {
            this.skillService.opensweetalert(
              "Successfully uploaded",
              "success",
              null
            );
          }
          this.draft_submission_status().subscribe(() => {
            if (fileid) {
              const pos = moodleObject.files.findIndex((data) => {
                return data.id == fileid;
              });
              moodleObject.files.splice(pos, 1);
              if (moodleObject.files.length == 0) {
                this.grading_submission_status(
                  this.skillService.user.course_assignment_id
                ).subscribe(() => {});
              }
            }
            if (!fileid) {
              this.router.navigateByUrl("/tabs/profile").then(() => {
                this.showProgressBar = false;
                this.showSplash = false;
              });
            } else {
              this.showProgressBar = false;
              this.showSplash = false;
            }
          });
        }
      }
    });
  }

  public sendFile(file: any, userInfo: any) {
    const self = this;
    let fileEncodedReplaced: string;
    let currentItemId;

    if (this.saveditemid != null) {
      // File item id already exist
      currentItemId = this.saveditemid;
    } else {
      // File item id not exist yet
      currentItemId = this.itemid;
    }

    // Substring MIME type from start until base64
    if (typeof file != "undefined") {
      if(typeof file.fileType != 'undefined') {
        const toSubstring = file.encodedFile.substring(
          0,
          file.encodedFile.indexOf("base64,") + 7
        );
        // Replace MIME type from file base64 URL to empty string
        fileEncodedReplaced = file.encodedFile.replace(toSubstring, "");
    }

      const formData = new FormData();
      formData.append("moodlewsrestformat", "json");
      formData.append("wstoken", this.userToken);
      formData.append("contextlevel", "user");
      formData.append("instanceid", userInfo[0].id);
      formData.append("component", "user");
      formData.append("filearea", "draft");
      formData.append("itemid", currentItemId);
      formData.append("filepath", "/");
      formData.append("filename", file.name);
      formData.append("filecontent", fileEncodedReplaced);

      return self.http.post(
        `${this.BASE_URL}/webservice/rest/server.php?wsfunction=core_files_upload`,
        formData
      );
    } else {
      const data$ = new Observable((observer) => {
        observer.next({ data: this.itemid });
        observer.complete();
      });
      return data$;
    }
  }
}
