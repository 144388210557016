import { Component, OnInit, ViewChild, Input, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { Platform, ModalController } from "@ionic/angular";
import { DomSanitizer } from "@angular/platform-browser";
import { RasaChatbotService } from "src/app/services/rasa-chatbot.service";
import { SkillService } from "src/app/services/skill.service";
import { OneSignalService } from "src/app/services/onesignal.service";
import { Router } from "@angular/router";

@Component({
	selector: "app-chatbot",
	templateUrl: "./chatbot.component.html",
	styleUrls: ["./chatbot.component.scss"]
})
export class ChatbotComponent implements AfterViewInit {
	@Input() modalOpened;
	public isMobile = false;
	public isTablet = false;
	public isDesktop = false;
	public isMobileWeb = false;
	public horizontalPosition = "";
	@ViewChild("content", { static: false }) public content: any;
	constructor(
		public router: Router,
		public sanitized: DomSanitizer,
		public changeDetectorRef: ChangeDetectorRef,
		public pfm: Platform,
		public modalContoller: ModalController,
		public rasaChatbotService: RasaChatbotService,
		public skillService: SkillService,
		public platform: Platform,
		public oneSignal: OneSignalService
	) {
		if (this.pfm.is("tablet")) {
			this.isTablet = true;
		}

		if (this.pfm.is("desktop")) {
			this.isDesktop = true;
			this.horizontalPosition = "end";
		}

		if (this.pfm.is("mobile")) {
			this.isMobile = true;
		}

		if (this.pfm.is("mobileweb")) {
			this.isMobileWeb = true;
		}

		if (this.isMobileWeb || this.isMobile || this.isTablet) {
			this.horizontalPosition = "start";
		}
	}

	/**
	 * Subscribe to message in order to notify when message was sent
	 */
	message() {
		this.subscribeMessage("newmessage", 800);
		this.subscribeMessage("fromUser", 700);
	}

	subscribeMessage(from, delay) {
		this.rasaChatbotService.chatbotCommunication.subscribe(data => {
			this.rasaChatbotService.newMsg = "";
			setTimeout(() => {
				this.content.scrollToBottom(500);
			}, delay);
		});
	}
	keyBoardEvent() {
		this.rasaChatbotService.keyBoardOpened = !this.rasaChatbotService.keyBoardOpened;
	}

	openChatBot() {
		this.rasaChatbotService.fabButtonOpen = false;
		setTimeout(() => {
			this.content.scrollToBottom(500);
		}, 500);
	}

	explore(skillId) {
		this.router.navigateByUrl(`/tabs/selected-skill/${skillId}`);
	}

	ngAfterViewInit() {
		if (this.modalOpened == true) {
			setTimeout(() => {
				this.rasaChatbotService.fabButtonOpen = false;
			}, 500);
		}
	}

	redirectUniv(data) {
		if (data) {
			this.router.navigateByUrl("tabs/device/" + data);
		} else {
			this.router.navigateByUrl("tabs/list");
		}
	}
}
