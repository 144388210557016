import { ElementRef, Injectable, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { University } from '../models/university';
import { Skill } from '../models/skill';
import { Certification } from '../models/certification';
import { User } from '../models/user';

@Injectable()

export abstract class SkillService {

  public abstract oneSkill: any;
  public abstract univDevices: any;
  // IOS tracking permission
  public abstract trackingPermission = false;
  public abstract submitted = false;
  public abstract user: User;
  public abstract currentCertification: Certification;
  public abstract moodleSkillsToggle: Certification;
  public abstract universities: University[];
  public abstract useCases: any;
  public abstract dot = new Array();
  public abstract userState: any = new BehaviorSubject('');
  public abstract isDownloading: boolean;
  public abstract animations = {
    listOfUniversitiesAnimation : false,
    awardedBadgesAnimation : false
  };
  public abstract userBadges: any;
  public abstract validationMessageLength();
  public abstract intersectionObserver(className);
  public abstract manageDemandState(currentObject, from?: string);
  public abstract setMoodleService(moodleSerice);
  public abstract checkUniversityOpenBadge();
  public abstract logout();
  public abstract opensweetalert(text, icon, timer?, title?);
  public abstract opensweetalertcst(contentBody, positionfile?);
  public abstract removeUserSkill(skill: Skill);
  public abstract updateMdlSkill(skill: Skill[]);
  public abstract allSkillsAssociated();
  public abstract fileWithoutSkillChosen();
  public abstract checkReadMoreBtnNeed(classParent);
  public abstract removeUserSkillFromEvidence(skill: Skill);
  public abstract downloadBadges(action: string, badgeid: string, skill?: Skill);
  public abstract addUserSkill(skill: Skill);
  public abstract addUserSkillToFiles(skill: Skill);
  public abstract submitCertification(moodleSkill);
  public abstract removeUserFile(position: number);
  public abstract addUserFiles(file: any, addFile: boolean);
  public abstract filterUseCases(skillName: string);
  public abstract addMdlUserSkill(skill: Skill);
  public abstract createFiles(action?: string);
  public abstract presentToast(message, durationTime?, connecting?, data?: string);
  public abstract presentActionSheet();
  public abstract alertSendDefinitely();
}


