import { Files } from "./files";
import { Skill } from "./skill";
import { User } from "./user";

export class Certification {
	public user: User;
	public _state: number;
	public _date: Date;
	public _files: Files[];
	public _skills: Skill[];
	public _mdl_skills: Skill[];

	constructor(state?, date?) {
		this._state = state;
		this._date = date;
		this._files = new Array<Files>();
		this._skills = new Array<Skill>();
		this._mdl_skills = new Array<Skill>();
	}

	get skills() {
		return this._skills;
	}

	set skills([]: any) {
		this._skills = [];
	}

	get files() {
		return this._files;
	}

	set files([]: any) {
		this._files = [];
	}

	get state() {
		return this._state;
	}
	set state(value: number) {
		this._state = value;
	}

	get date() {
		return this._date;
	}
	set date(value: Date) {
		this._date = value;
	}

	/**
	 * Add new skill in cart
	 * @param {Skill} skill
	 * @memberOf Certification(not connected user)
	 */

	addSkill(skill: Skill) {
		const pos: number = this._skills.findIndex((currentSkill: Skill) => {
			return skill.id === currentSkill.id;
		});

		if (pos == -1) {
			this._skills.push(skill);
		}
	}

	/**
	 * Add new skill in evidence
	 * @param {Skill} skill
	 * @memberOf  moodleSkillsToggle(connected user)
	 */
	addSkillToFiles(skill: Skill) {
		this.files.forEach(file => {
			if (file.skillsAssociated != undefined) {
				const pos: number = file.skillsAssociated.findIndex(x => x.skill.name == skill.name);

				if (pos === -1) {
					file.skillsAssociated.push({ selected: false, skill: skill });
				}
			}
		});
	}

	/**
	 * Remove a file from cart
	 * @param {Skill} skill
	 * @memberOf Certification(not connected user)
	 */
	removeSkill(skill: Skill) {
		const pos: number = this._skills.findIndex((currentSkill: Skill) => {
			return skill.id === currentSkill.id;
		});
		if (pos != -1) {
			this._skills.splice(pos, 1);
		}
	}

	/**
	 * Remove a file from cart
	 * @param {Skill[]} skills
	 * @memberOf MoodleSkillsToggle(connected user)
	 */
	updateMdlSkill(skills: Skill[]) {
		if (skills.length === 0) {
			this._mdl_skills.length = 0;
		}

		skills.forEach((item, index) => {
			if (index === 0) {
				this._mdl_skills.length = 0;
			}
			this._mdl_skills.push(item);
		});
	}

	/**
	 * Add new file in evidence
	 * @param {Files} file
	 * @param {boolean} fileAdded
	 * @memberOf Certification && MoodleSkillsToggle
	 */

	addFile(file: Files) {
		this.files.push(file);
		return file;
	}

	/**
	 * Add new skill in evidence
	 * @param {Skill} skill
	 * @memberOf MoodleSkillsToggle
	 */

	addMdlSkill(skill: Skill) {
		const pos: number = this._mdl_skills.findIndex((currentSkill: Skill) => {
			return skill.id === currentSkill.id;
		});

		if (pos === -1) {
			this._mdl_skills.push(skill);
		}
	}

	/**
	 * Remove a file
	 * @param {number} position
	 * @memberOf Certification && MoodleSkillsToggle
	 */
	removeFile(position: number) {
		this.files.splice(position, 1);
	}

	/**
	 * Remove skill from evidence
	 *  @param {Skill} skill
	 * @memberOf MoodleSkillsToggle
	 */

	removeSkillFromFiles(skill: Skill) {
		this.files.forEach(file => {
			if (file.skillsAssociated != undefined) {
				file.skillsAssociated.forEach((skillAssociated, index, currentArr) => {
					const posToBeAssociated: number = file.skillsAssociatedChosen.findIndex(x => x == skill.name);

					if (posToBeAssociated != -1) {
						file.skillsAssociatedChosen.splice(posToBeAssociated, 1);
					}

					const posToAssociated: number = file.skillsAssociated.findIndex(x => x.skill.name == skill.name);
					if (skill.name == skillAssociated.skill.name && posToAssociated != -1) {
						currentArr.splice(posToAssociated, 1);
					}
				});
			}
		});
	}

	/**
	 * Load certification object with localstorage on page load
	 * @memberOf Certification(user not connected)
	 * @return object
	 */

	loadJsonNotConnected() {
		let result = JSON.parse(localStorage.getItem("currentCertification"));
		this._skills = result._skills;
		this.skills.badge = result._skills.badge;
		this.files = result.files;
		return result;
	}

	/**
	 * Load MoodleSkillsToggle object with localstorage on page load
	 * @memberOf MoodleSkillsToggle(user connected)
	 * @return object
	 */

	loadJsonConnected() {
		let result = JSON.parse(localStorage.getItem("moodleSkillsToggle"));
		this._mdl_skills = result._mdl_skills;
		return result;
	}
}
