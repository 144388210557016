import { Injectable, ElementRef, ViewChild } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Files } from '../models/files';
@Injectable({
  providedIn: 'root'
})
export class FilesService {
  public fileReader = new FileReader();
  public skillAdd: any;
  public alreadyVerified = false;


  @ViewChild('myCanvas', {static: false}) public myCanvas: ElementRef<HTMLCanvasElement>;
  public context: CanvasRenderingContext2D;

  constructor(public toastController: ToastController) { }


  /**
   * Add new file
   *
   * @param event Files event
   * @param fileTarget Files object
   * @param  canvas HTMLCanvasElement element
   */

  addInputFile(event, fileTarget: Files, canvas: ElementRef<HTMLCanvasElement>) {

        let fileExtension, authorized;
        /** INPUT FILE SYSTEM */
        const file = event.target.files[0];
        const fileName = file.name.toLowerCase();
        fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
        authorized =  this.fileTypesAccepted(fileExtension);
        if (authorized) {
            if (fileTarget && event.srcElement.value != "") {
                this.fileReader.onload = (e) => {
                  if (fileExtension == 'png' || fileExtension == 'PNG') {
                    this.context = canvas.nativeElement.getContext("2d");
                    // Create canvas if img format is PNG
                    this.createCanvasImage(fileTarget,canvas);
                  } else {
                    fileTarget.encodedFile = this.fileReader.result;
                  }

                  if (this.skillAdd) {
                    this.presentToast(file);
                  }
                };

                // Create asynchrone file
                this.fileCreation(file, event, fileTarget, this.fileReader);
                // Empty last file value for adding it again
                event.srcElement.value = '';
            }
        } else {
          this.presentToast(`The file extension ${fileExtension} is not accepted`,3000);
        }
  }

  async presentToast(message: string, durationTime?: number) {
    const toast = await this.toastController.create({
      message,
      duration: typeof durationTime != 'undefined' ? durationTime : 5000,
      color: 'favorite'
    });
    toast.present();
  }

  /**
   * Create canvas in order to change PNG file extension to JPEG
   */

  createCanvasImage(fileTarget, canvas) {
    this.context = canvas.nativeElement.getContext("2d");
    const image = new Image();
    image.src = this.fileReader.result as string;
    image.onload = () => {
      canvas.nativeElement.width = image.width;
      canvas.nativeElement.height = image.height;

      this.context.drawImage(image, 0, 0, image.width, image.height);
      const dataURI = canvas.nativeElement.toDataURL("image/jpeg", 0.8);
      fileTarget.encodedFile = dataURI;
    }
  }

 /**
  * Create new file
  * 
  * @param file  HTML5 file
  * @param event HTML5 file event
  * @param fileTarget Files object
  * @param fileReader FileReader
  */

  fileCreation(file, event, fileTarget: Files, fileReader?: FileReader) {
    fileReader.readAsDataURL(file);
    fileTarget.url = file.name;
    const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;

    // Convert image extension if img is png
    if (fileExtension == 'png' || fileExtension == 'PNG') {
      this.changeFileName(event.target.files[0].name, fileTarget);
      const fileName =  event.target.files[0].name.toLowerCase().substring(0, file.name.lastIndexOf('.')) || file.name;
      fileTarget.name = fileName + '.jpeg';
      fileTarget.fileType = 'image/jpeg';
    } else {
      fileTarget.name = file.name;
      fileTarget.fileType = file.type;
    }
  }

    /**
     * Change file name
     *
     * @param fileName File name
     * @param fileTarget Files object
     */

    changeFileName(fileName: string, fileTarget: Files) {
      const extractedFileName = fileName.toLowerCase().substring(0, fileName.lastIndexOf('.')) || fileName;
      fileTarget.name = extractedFileName + '.jpeg';
    }

  /**
   * Form validation for authorized files
   *
   * @param  fileExtension File extension
   */

  fileTypesAccepted(fileExtension: string) {
    const fileAccepted = ['pdf', 'jpeg', 'jpg', 'png', 'doc', 'ppt', 'pptx', 'docx', 'odp', 'odt', 'txt'];
    return fileAccepted.some(extension => {
      return fileExtension == extension;
    });
  }

}
