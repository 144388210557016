import { Injectable} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SkillService } from "./skill.service";
import { University } from "../models/university";
import { Skill } from "../models/skill";
import { Certification } from "../models/certification";
import { User } from "../models/user";
import { AuthService } from "./auth.service";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File, FileEntry } from "@ionic-native/file/ngx";
import { ToastController, ActionSheetController, ModalController, Platform, NavController } from "@ionic/angular";
import { RealMoodleService } from "./real-moodle.service";
import { SkillsMappingComponent } from "../components/skills-mapping/skills-mapping.component";
import { HttpClient } from "@angular/common/http";
import { FilesService } from "./files.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Files } from "../models/files";
import { OneSignalService } from "./onesignal.service";
import { UserCredential } from "../models/UserCredential";
import { Router } from "@angular/router";
@Injectable({
	providedIn: "root"
})
export class RealSkillService implements SkillService {
	public submitted = false;
	public univDevices: any;
	public oneSkill: Skill;
	/** IOS tracking permission */
	public trackingPermission = false;
	public currentCertification: Certification;
	public moodleSkillsToggle: Certification;
	public universities: University[];
	public notDuplicateSkillAssociated = new Array();
	public user: User;
	public userState: any;
	public dot = new Array();
	public awardedBadgesAnimation = false;
	public animations = {
		listOfUniversitiesAnimation: false,
		awardedBadgesAnimation: false
	};
	public useCases: any;
	public userBadges: any;
	public baseAdminToken = "8a0f6a4af1e87a0337a9b10c7eae9802";
	public isDownloading = false;

	constructor(
		public moodleService: RealMoodleService,
		public file: File,
		public fileOpener: FileOpener,
		public pfm: Platform,
		public authService: AuthService,
		private toastController: ToastController,
		public filesService: FilesService,
		public actionSheetController: ActionSheetController,
		public navCtrl: NavController,
		public modalContoller: ModalController,
		public oneSignal: OneSignalService,
		public router: Router,
		public http: HttpClient
	) {
		if (!localStorage.getItem("currentCertification")) {
			this.currentCertification = new Certification(0);
		} else {
			this.currentCertification = new Certification(0);
			this.currentCertification.loadJsonNotConnected();
		}

		if (!localStorage.getItem("moodleSkillsToggle")) {
			this.moodleSkillsToggle = new Certification(0);
		} else {
			this.moodleSkillsToggle = new Certification(0);
			this.moodleSkillsToggle.loadJsonConnected();
		}

		this.user = this.authService.loadUser("user");
		this.userState = new BehaviorSubject(this.user);
	}

	/**
	 * Observe the intersection of the element where the page is visible
	 *
	 * @param className css class
	 */

	public intersectionObserver(className) {
		const observer = new IntersectionObserver(
			entries => {
				entries.forEach(item => {
					if (item.intersectionRatio > 0.5) {
						item.target.classList.remove("not-visible");
						observer.unobserve(item.target);
					}
				});
			},
			{
				threshold: [0.5]
			}
		);
		const intersectionObserver = document.querySelectorAll(`.${className}`);
		intersectionObserver.forEach(item => {
			item.classList.add("not-visible");
			observer.observe(item);
		});
	}

	public checkReadMoreBtnNeed(classParent) {
		const classParentDomAccess = classParent;
		const btnAddClassname = this.pfm.is("mobile") || this.pfm.is("mobileweb") ? "icon-add-mt" : "icon-add";
		// if (classParentDomAccess.offsetHeight > 0 && classParentDomAccess.scrollHeight > 0) {
		if (
			classParentDomAccess.offsetHeight < classParentDomAccess.scrollHeight ||
			classParentDomAccess.offsetWidth < classParentDomAccess.scrollWidth
		) {
			if (classParentDomAccess.querySelector(`.${btnAddClassname}`).classList.contains("hide")) {
				classParentDomAccess.querySelector(`.${btnAddClassname}`).classList.remove("hide");
			}
		} else {
			if (classParentDomAccess.querySelector(`.${btnAddClassname}`)) {
				classParentDomAccess.querySelector(`.${btnAddClassname}`).classList.add("hide");
			}
		}
	}

	/**
	 * Remove not connected user skill from cart
	 *
	 * @param skill skill to remove
	 */

	public removeUserSkill(skill: Skill) {
		if (!this.user.auth.connected) {
			this.currentCertification.removeSkill(skill);
			localStorage.setItem("currentCertification", JSON.stringify(this.currentCertification));
		}
	}

	/**
	 * Remove connected user skill from cart
	 *
	 * @param skills Array of skills
	 */

	public updateMdlSkill(skills: Skill[]) {
		this.moodleSkillsToggle.updateMdlSkill(skills);
		localStorage.setItem("moodleSkillsToggle", JSON.stringify(this.moodleSkillsToggle));
	}

	/**
	 * Remove selected skill from evidence
	 *
	 * @param skill Object
	 */

	public removeUserSkillFromEvidence(skill: Skill) {
		if (this.user.auth.connected) {
			this.moodleSkillsToggle.removeSkillFromFiles(skill);
		}
	}

	/**
	 * Add new skill in the cart for connected user
	 *
	 *  @param skill Object
	 */

	public addMdlUserSkill(skill: Skill) {
		this.moodleSkillsToggle.addMdlSkill(skill);
	}

	/**
	 * Add new skill in the cart for not connected user
	 *
	 * @param skill Object
	 */

	public addUserSkill(skill: Skill) {
		if (!this.user.auth.connected) {
			this.currentCertification.addSkill(skill);
			localStorage.setItem("currentCertification", JSON.stringify(this.currentCertification));
		}
	}

	/**
	 * Add new skill in evidence
	 *
	 * @param skill Object
	 */

	public addUserSkillToFiles(skill: Skill) {
		this.moodleSkillsToggle.addSkillToFiles(skill);
	}

	/**
	 * Remove a file from evidence
	 *
	 * @param position File position in object
	 */

	public removeUserFile(position: number) {
		if (this.moodleSkillsToggle.files.length == 1) {
			let body;
			const promise1 = this.moodleService.getStrings("alert:remove_file");
			const promise2 = this.moodleService.getStrings("alert:confirmation_send_confirmation_button");
			const promise3 = this.moodleService.getStrings("alert:confirmation_send_cancel_button");

			if (this.moodleSkillsToggle.files.length == 1) {
				Promise.all([promise1, promise2, promise3]).then(value => {
					body = {
						text: value[0],
						icon: "question",
						confirmButtonText: value[1],
						cancelButtonText: value[2]
					};
					this.opensweetalertcst(body, position);
					return;
				});
			}
		} else {
			this.moodleSkillsToggle.removeFile(position);
		}
	}

	/**
	 * Add new file in evidence
	 *
	 * @param file File Object
	 */

	public addUserFiles(file: Files) {
		this.moodleSkillsToggle.addFile(file);
	}

	/**
	 * Create files
	 *
	 */

	public createFiles() {
		this.moodleService.showSplash = true;
		this.moodleService.getUserInfos("username", this.user.auth.username, this.user.moodleToken).subscribe(
			userInfo => {
				if (userInfo != null) {
					this.moodleService.recursiveUpload(this.moodleSkillsToggle.files, userInfo);
				}
			},
			err => {
				this.moodleService.manageUnexpectedErrors();
			}
		);
	}

	logout() {
		this.userBadges = undefined;
		this.authService.signInfo.next("user:loggedout");
		if (typeof this.moodleService.badges != "undefined") {
			this.moodleService.badges.forEach(badge => {
				badge.selected = false;
				badge.awarded = false;
			});
		}
		if (this.oneSkill) {
			this.oneSkill.awarded = false;
		}
		if (typeof this.univDevices != "undefined") {
			this.univDevices.forEach(device => {
				device.subscribed = false;
			});
		}
		this.moodleService.linkedSkills = undefined;
		this.moodleService.onlineTextContent = undefined;
		this.user = new User();
		this.moodleSkillsToggle._files = new Array();
		this.moodleSkillsToggle._mdl_skills = new Array();
		this.user.universityChoice = "";
		this.user.moodleToken = undefined;
		this.filesService.alreadyVerified = false;
		this.user.badges = [];
		this.user.auth = new UserCredential();
		this.user.auth.connected = false;
		this.moodleService.showSplash = false;
		this.oneSignal.setMoodle(this.moodleService);
		this.oneSignal.alreadyInit = true;
		this.oneSignal.disconnect();
		localStorage.removeItem("item");
		// Save user object
		this.authService.saveUser(this.user);
		this.user = this.user;
		this.currentCertification.skills.length = 0;
		localStorage.setItem("moodleSkillsToggle", JSON.stringify(this.moodleSkillsToggle));
		this.router.navigateByUrl("/tabs/login");
	}

	/**
	 * Verify if the university choice concerned for OpenBadge
	 */

	checkUniversityOpenBadge() {
		return this.user.universityChoice === "Both" || this.user.universityChoice === "OpenBadge";
	}

	async presentToast(message: string, durationTime?: number, connecting?: boolean, data?: string) {
		let toast;
		try {
			toast.dismiss();
		} catch (e) {}

		let buttons;
		if (connecting) {
			buttons = [
				{
					side: "start",
					text: data,
					handler: () => {
						this.navCtrl.navigateForward("/tabs/profile");
					}
				}
			];
		}

		toast = await this.toastController.create({
			message,
			duration: typeof durationTime != "undefined" ? durationTime : 5000,
			color: "favorite",
			cssClass: this.pfm.is("desktop") ? "toast-custom-class" : "",
			buttons
		});
		toast.present();
	}

	/**
	 * Filter use cases
	 *
	 * @param skillName  Skill name
	 */

	filterUseCases(skillName: string) {
		this.moodleService.useCases.forEach(item => {
			const skills = item.value.split(",");
			item.skills = skills;

			const result = item.skills.some(res => {
				return res.trim() == skillName.trim();
			});
			if (result) {
				item.display = true;
			} else {
				item.display = false;
			}
		});

		this.useCases = this.moodleService.useCases.filter(item => {
			return item.display;
		});
	}

	/**
	 * Open an action dialog for the export of skills
	 */

	async presentActionSheet() {
		const promise1 = this.moodleService.getStrings("actionsheet:export_format_body_copy");
		const promise2 = this.moodleService.getStrings("actionsheet:export_format_body_copy");
		const promise3 = this.moodleService.getStrings("actionsheet:export_format_cancel");

		Promise.all([promise1, promise2, promise3]).then((value: any) => {
			this.actionSheetController
				.create({
					header: value[0],
					buttons: [
						{
							text: value[1],
							icon: "copy",
							handler: () => {
								this.skillsMappingClipboard();
							}
						},
						{
							text: "PDF",
							icon: "book",
							handler: () => {
								this.skillsMappingPdf();
							}
						},
						{
							text: value[2],
							icon: "close",
							role: "cancel",
							handler: () => {}
						}
					]
				})
				.then(action => {
					action.present();
				});
		});
	}

	/**
	 * Download badges in zip format(all badges) or in png format(individual) for Desktop
	 *
	 * @param e Event
	 * @param action All badges or individual badge
	 * @param badgeid Badge id
	 * @param skill   Skill object
	 */

	downloadBadges(action: string, badge: any, skill: Skill = null) {
		if (skill == null) {
			this.isDownloading = true;
		}

		if (action == "all") {
			this.moodleService.downloadBadges(this.user.auth.id, true).subscribe(
				x => {
					const newBlob = new Blob([x], { type: "application/zip" });
					const zipFile = this.user.auth.fullName + "_badges.zip";

					if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
						// Create link to download element
						const data = (window.URL || (window as any) || {}).createObjectURL(newBlob);
						const link = document.createElement("a");
						link.href = data;
						link.download = zipFile;

						// Firefox
						link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));
						setTimeout(() => {
							window.URL.revokeObjectURL(data);
							link.remove();
						}, 100);
					}

					// FOR MOBILE AND TABLET
					if (this.pfm.is("mobile") && !this.pfm.is("mobileweb")) {
						this.downloadBadgesMT(zipFile, newBlob, "all");
					}
				},
				err => {
					console.log("errr", err);
				},
				() => {
					this.isDownloading = false;
				}
			);
		} else {
			if (skill) {
				skill.isDownloading = true;
			}

			this.moodleService.downloadBadges(this.user.auth.id, false, badge.id).subscribe(
				x => {
					const newBlob = new Blob([x], { type: "image/png" });
					const r = Math.random().toString(36).substring(7);
					const fileName = badge.name + "_" + r + "_.png";

					if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
						const data = (window.URL || (window as any) || {}).createObjectURL(newBlob);
						const link = document.createElement("a");
						link.href = data;
						link.download = fileName;

						// Firefox
						link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));
						setTimeout(() => {
							window.URL.revokeObjectURL(data);
							link.remove();
						}, 100);
					}

					// FOR MOBILE AND TABLET
					if (!this.pfm.is("desktop") && !this.pfm.is("mobileweb")) {
						this.downloadBadgesMT(fileName, newBlob, "individual");
					}
				},
				err => {},
				() => {
					if (skill) {
						skill.isDownloading = false;
					} else {
						this.isDownloading = false;
					}
				}
			);
		}
	}

	/**
	 * Pass the object status to awarded if user awarded a badge
	 *
	 * @param skill User skill
	 * @param fromPage Page called
	 */

	manageDemandState(skill: any, fromPage: string) {
		if (this.user.auth.id && typeof this.user.auth.id != "undefined") {
			this.moodleService.getUserAcquiredBadges(this.user.auth.id).subscribe(
				(badges: any) => {
					if (typeof badges.exception == "undefined") {
						if (fromPage == "selected-skill") {
							badges.find(badge => {
								if (String(badge.badgeid) == String(skill.id)) {
									this.user.badges.push(skill);
									skill.awarded = true;
								}
							});
						} else {
							skill.find(curBadge => {
								badges.find(badge => {
									if (String(badge.badgeid) == String(curBadge.id)) {
										this.user.badges.push(badge);
										curBadge.awarded = true;
									}
								});
							});
						}
					}
				},
				err => {
					this.moodleService.manageUnexpectedErrors();
				}
			);
		}
	}

	/**
	 * Download all badges or individual badge on mobile and tablet
	 *
	 * @param fileName File name
	 * @param newBlob blob file
	 * @param action All badges or individual badge
	 */

	downloadBadgesMT(fileName: string, newBlob: Blob, action: string) {
		const filePath = this.pfm.is("android") ? this.file.externalRootDirectory : this.file.dataDirectory;
		if (action == "all") {
			this.file
				.writeFile(filePath, fileName, newBlob, { replace: true })
				.then((fileEntry: FileEntry) => {
					this.file
						.writeFile(filePath, fileName, newBlob, { replace: true })
						.then((fileEntry2: FileEntry) => {
							this.fileOpener
								.open(fileEntry2.toURL(), "application/zip")
								.then(() => console.log("File is opened"))
								.catch(err => console.error("Error openening file: " + err));
						})
						.catch(err => {
							console.error("Error creating file: " + err);
						});
				})
				.catch(err => {
					console.error("Error creating file: " + err);
				});
		} else {
			this.file
				.writeFile(filePath, fileName, newBlob, { replace: true })
				.then((fileEntry: FileEntry) => {
					this.fileOpener
						.open(fileEntry.toURL(), "image/png")
						.then(() => console.log("File is opened"))
						.catch(err => console.error("Error openening file: " + err));
				})
				.catch(err => {
					console.error("Error creating file: " + err);
				});
		}
	}

	/**
	 * Display skills mapping in pdf format on mobile and tablet
	 *
	 * @param newBlob pdf blob
	 */

	showSkillsPDFMT(newBlob: Blob) {
		const filePath = this.pfm.is("android") ? this.file.externalRootDirectory : this.file.cacheDirectory;
		this.file
			.writeFile(filePath, "certificate.pdf", newBlob, { replace: true })
			.then((fileEntry: FileEntry) => {
				this.fileOpener
					.open(fileEntry.toURL(), "application/pdf")
					.then(() => console.log("File is opened"))
					.catch(err => console.error("Error openening file: " + err));
			})
			.catch(err => {
				console.error("Error creating file: " + err);
			});
	}

	/**
	 * Copy/Paste function
	 */
	async skillsMappingClipboard() {
		const modal = await this.modalContoller.create({
			component: SkillsMappingComponent,
			componentProps: {
				userBadges: this.userBadges
			}
		});
		return await modal.present();
	}

	/**
	 * Display skills mapping in PDF
	 */

	skillsMappingPdf() {
		if (this.user.auth.connected) {
			this.moodleService.getAllSkills().then(
				(getAllSkills: any) => {
					this.moodleService.getUserAcquiredBadges(this.user.auth.id).subscribe((data: any) => {
						let communArray = new Array();
						getAllSkills.find(skill => {
							data.find(item => {
								if (String(skill.id) == String(item.badgeid)) {
									communArray.push(skill);
								}
							});
						});

						this.moodleSkillsToggle._mdl_skills.forEach(skill => {
							communArray.push(skill);
						});

						communArray = Array.from(new Set(communArray.map(a => a.id))).map(id => {
							return communArray.find(a => a.id == id);
						});

						this.moodleService.showUserCertificateSkills(communArray).subscribe((x: any) => {
							const newBlob = new Blob([x], { type: "application/pdf" });

							if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
								if (window.navigator && window.navigator.msSaveOrOpenBlob) {
									window.navigator.msSaveOrOpenBlob(newBlob);
								} else {
									const fileURL = window.URL.createObjectURL(newBlob);
									window.location.href = fileURL;
								}
							}

							if (!this.pfm.is("desktop") && !this.pfm.is("mobileweb")) {
								this.showSkillsPDFMT(newBlob);
							}
						});
					});
				},
				err => {
					console.error(this.moodleSkillsToggle);
				}
			);
		} else {
			this.moodleService.showUserCertificateSkills(this.currentCertification.skills).subscribe((x: any) => {
				const newBlob = new Blob([x], { type: "application/pdf" });

				if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
						window.navigator.msSaveOrOpenBlob(newBlob);
					} else {
						const url = window.URL.createObjectURL(newBlob);
						window.location.href = url;
					}
				}
				if (!this.pfm.is("desktop") && !this.pfm.is("mobileweb")) {
					this.showSkillsPDFMT(newBlob);
				}
			});
		}
	}

	/**
	 * Verify if all evidences has at least one associated skill
	 *
	 * @returns Return true if all skills have been associated
	 */

	allSkillsAssociated() {
		let result;
		this.notDuplicateSkillAssociated = new Array();
		const skillsAssociatedArr = new Array();

		this.moodleSkillsToggle._files.forEach(file => {
			file.skillsAssociatedChosen.some(skillsAssociated => {
				skillsAssociatedArr.push(skillsAssociated);
			});
		});

		// Push not duplicate values in new array
		skillsAssociatedArr.forEach(item => {
			const findIndex = this.notDuplicateSkillAssociated.findIndex(skill => skill == item);
			if (findIndex == -1) {
				this.notDuplicateSkillAssociated.push(item);
			}
		});

		// Return true if all skills have been associated
		if (typeof this.moodleSkillsToggle._mdl_skills != "undefined") {
			if (this.moodleSkillsToggle._mdl_skills.length == this.notDuplicateSkillAssociated.length) {
				result = true;
			} else {
				result = false;
			}
		}
		return result;
	}

	/**
	 * Verify if the comment field of the evidence contains at least 5 characters
	 *
	 * @returns True if texts contain at least 5 characters
	 */

	validationMessageLength() {
		return this.moodleSkillsToggle.files.every(file => {
			return file.message.length >= 5;
		});
	}

	/**
	 * Simple modal
	 *
	 * @param text Content text
	 * @param icon Icon
	 * @param timer timer to be displayed
	 * @param title Content title
	 */

	opensweetalert(text: string, icon: string, timer: number = null, title?: string) {
		return Swal.fire({
			text,
			icon,
			title: typeof title != "undefined" ? title : undefined,
			showConfirmButton: timer != null ? false : true,
			timer: timer != null ? timer : undefined
		});
	}

	/**
	 * Complex modal
	 *
	 * @param bodyContent Modal content
	 * @param position File position to be removed
	 */

	opensweetalertcst(bodyContent: any, position?: number) {
		const { title, icon, confirmButtonText, cancelButtonText, text } = bodyContent;

		Swal.fire({
			title: typeof title != "undefined" ? title : undefined,
			text: typeof text != "undefined" ? text : undefined,
			icon,
			showCancelButton: true,
			confirmButtonText,
			cancelButtonText
		}).then(result => {
			if (result.value) {
				if (typeof position != "undefined") {
					this.moodleSkillsToggle.removeFile(position);
					this.createFiles();
				} else {
					this.submitCertification();
				}
			}
		});
	}

	/**
	 * Verify if the evidence contains at least one associated Skill
	 *
	 * @returns True if the evidence contains at least one associated Skill
	 */

	fileWithoutSkillChosen(): boolean {
		const fileWithoutSkillChosen = this.moodleSkillsToggle._files.some(file => {
			return file.skillsAssociatedChosen.length == 0;
		});
		return fileWithoutSkillChosen;
	}

	/**
	 * Toast
	 */

	alertSendDefinitely() {
		const promise1 = this.moodleService.getStrings("alert:confirmation_send_title");
		const promise2 = this.moodleService.getStrings("alert:confirmation_send_confirmation_button");
		const promise3 = this.moodleService.getStrings("alert:confirmation_send_cancel_button");

		Promise.all([promise1, promise2, promise3]).then(value => {
			const body = {
				title: value[0],
				icon: "question",
				confirmButtonText: value[1],
				cancelButtonText: value[2]
			};
			this.opensweetalertcst(body);
		});
	}

	/**
	 * Submit certificate demand
	 */

	public submitCertification() {
		this.submitted = true;
		const formValid = this.allSkillsAssociated() && !this.fileWithoutSkillChosen() && this.validationMessageLength();

		// Send if form is valid
		if (formValid) {
			this.submitted = false;
			this.moodleService.showSplash = true;
			this.moodleService.showProgressBar = true;

			// Change submission status from draft area to ready to be graded
			this.moodleService.grading_submission_status(this.user.course_assignment_id).subscribe(() => {});
			this.moodleService.onlineTextContent = undefined;

			this.moodleService.getStrings("alert:notification").then((data: string) => {
				this.opensweetalert(data, "success");
			});

			this.moodleService.notifyTeacherAboutSubmit(this.user.enroled_courses_id[0], this.user.auth.id).subscribe(data => {});

			this.moodleService.removeAllSkillsChosen(this.user.moodleToken).subscribe(
				() => {
					//  Remove all skills from 'SELECTED SKILL' Page
					if (typeof this.oneSkill != "undefined") {
						this.oneSkill.selected = false;
					}

					//  Remove all skills from 'SKILL EXPLORER' Page
					if (this.moodleService.badges) {
						this.moodleService.badges.forEach(item => {
							item.selected = false;
						});
					}
					this.moodleSkillsToggle._state = 1;

					this.moodleSkillsToggle = new Certification(0);
					this.currentCertification = new Certification(0);

					this.moodleService.grading_submission_status(this.user.course_assignment_id).subscribe(item => {});
					localStorage.setItem("currentCertification", JSON.stringify(this.currentCertification));
					localStorage.setItem("moodleSkillsToggle", JSON.stringify(this.moodleSkillsToggle));

					this.moodleService.showSplash = false;
				},
				err => {
					this.moodleService.manageUnexpectedErrors();
				}
			);
		} else {
			this.moodleService.getStrings("alert:correct_form").then((data: string) => {
				this.opensweetalert(data, "error");
			});
		}
	}

	/**
	 * Update Moodle service state
	 *
	 * @param moodle Moodle service
	 */

	public setMoodleService(moodle: any) {
		this.moodleService = moodle;
	}
}
