import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { GdprGuardService } from 'src/app/services/gdpr-guard.service';
import { RealMoodleService } from 'src/app/services/real-moodle.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  panelOpenState = false;
  public isTablet;
  public isMobile;
  public gdprTitle;
  public gdpr;
  public acceptedButtonClicked = false;
  @Input() url: any;
  @Input() public gdprGuard: any;

  constructor(public modalController: ModalController,
              public platform: Platform, public moodleService: RealMoodleService) {
    this.isTablet = this.platform.is("tablet");
    this.isMobile = this.platform.is("mobile");
   }


   /**
    * Load GDPR from guard service
    */

  ngOnInit() {
    this.gdpr = this.gdprGuard.gdprContent;
  }

}
