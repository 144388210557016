import { Component, OnInit, Input } from '@angular/core';
import { SkillService } from 'src/app/services/skill.service';
import { RealMoodleService } from 'src/app/services/real-moodle.service';

@Component({
  selector: 'app-download-badges',
  templateUrl: './download-badges.component.html',
  styleUrls: ['./download-badges.component.scss'],
})
export class DownloadBadgesComponent {

  @Input('quantity') quantity:string;
  constructor(public skillService:SkillService,public moodleService:RealMoodleService) { }
  

}
