import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "app-percent-circle",
	templateUrl: "./percent-circle.component.html",
	styleUrls: ["./percent-circle.component.scss"]
})
export class PercentCircleComponent implements OnInit {
	@Input() percent: number;
	@Input() badgeid: number;
	@Input() color = "red";
	@Input() badgeurl: string;
	public random: any;

	public var = 1;
	public allBadges: any = new Array();
	constructor() {
		this.randomNumber();
	}

	ngOnInit() {
		this.defineStrokeColor();
	}

	defineStrokeColor() {
		if (this.color == "blueShade") {
			this.color = "#63B9E9";
		} else if (this.color == "purpleShade") {
			this.color = "#A892CC";
		} else if (this.color == "pinkShade") {
			this.color = "#F68FBC";
		} else if (this.color == "yellowShade") {
			this.color = "#FFD810";
		} else if (this.color == "greenShade") {
			this.color = "#008000";
		}
	}

	randomNumber() {
		this.random = Math.random().toString(36).substring(4);
	}
}
