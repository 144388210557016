import { Injectable, ViewChild, Renderer2, ElementRef, RendererFactory2 } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { User } from "../models/user";
import { SkillService } from "./skill.service";
import { IonContent, Events } from "@ionic/angular";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { RealMoodleService } from "./real-moodle.service";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class RasaChatbotService {
	private BASE_URL = "https://nlu-api.european-crossborder-skills.eu/webhooks/rest/webhook";
	public fabButtonOpen = true;
	private UNIVERSITYY_PROGRAMMS_MODEL = "https://nlu-api.european-crossborder-skills.eu/webhooks/rest/webhook";
	private SKILL_MODEL = "https://nlu-api.european-crossborder-skills.eu/skills/webhooks/rest/webhook";
	public displayMessage;
	public programme: string;
	public universityDeviceChosen = false;
	public skillChosen = false;
	public isMobile = false;
	public keyBoardOpened = false;
	public qtnRelatedProgramme = false;
	public isWriting = false;
	public universityAlreadySearched = false;
	public universityDeviceAlreadySearched = false;

	messages: any = [];
	currentUser = "Student";
	isDesktop = false;
	renderer: Renderer2;
	user: User;
	public chatbotCommunication = new Subject<any>();
	connectSid: string;
	newMsg = "";
	firstTimeOpened = false;
	@ViewChild("content", { static: false }) public content: any;

	constructor(
		public sanitized: DomSanitizer,
		public authService: AuthService,
		public router: Router,
		public http: HttpClient,
		public auuthService: AuthService,
		public skillService: SkillService,
		public moodleService: RealMoodleService
	) {
		this.connectSid = this.authService.returnHash();
		this.writeWelcomeMessage();
	}

	generateNewConnectSid() {
		this.connectSid = this.authService.returnHash();
	}

	writeWelcomeMessage() {
		if (!this.firstTimeOpened) {
			this.getUnivProgrammsResponse("Hello").subscribe((rasaMessage: any) => {
				if (rasaMessage.length === 0) {
					this.isWriting = false;
					return;
				}
				const [rasaMessageObject] = rasaMessage;
				this.messages.push({
					_id: Math.random().toString(36).substring(2, 15),
					message: rasaMessageObject.text,
					role: "ECBS",
					msgWelcome: true,
					message_date: new Date()
				});
			});
			this.firstTimeOpened = true;
		}
	}

	/**
	 * Navigation
	 */
	navigationPage(element?) {
		let universityChosen = false;
		this.universityDeviceAlreadySearched = true;
		// if (this.router.url.startsWith('/tabs/device/') && this.messages.length == 1) {
		//   return;
		// }

		if (this.messages.length > 0) {
			if (this.router.url === "/tabs/list") {
				this.moodleService.allUniversities.find((university: any) => {
					this.messages.find(message => {
						if (typeof message.message.toLocaleLowerCase === "function") {
							if (message.message.toLocaleLowerCase().includes(university.shortname.toLowerCase())) {
								universityChosen = true;
							}
						}
					});
				});
			}
		}

		if (this.messages.length <= 2 && this.router.url.startsWith("/tabs/skill-explorer")) {
			return;
		}

		if (this.router.url.startsWith("/tabs/device")) {
			if (this.universityDeviceChosen) {
				return;
			}

			this.messages.forEach(message => {
				if (!message.message.includes("choose")) {
					return;
				}
			});
		}

		if (universityChosen || this.skillChosen) {
			return;
		}
		this.newMsg = element;

		this.sendMessage("fromNavigation");
	}

	sendMessage(data) {
		let delay = 0;
		if (data == "fromNavigation") {
			delay = 0;
		} else {
			delay = 1500;
		}

		let skillsDisplayed = new Array();
		let splittedRasaData;
		let currentUniv;

		this.messages.push({
			role: "Student",
			message_date: new Date().getTime().toString(),
			message: this.newMsg
		});
		let currentMessage = this.newMsg;
		this.newMsg = "";
		this.chatbotCommunication.next("fromUser");
		this.isWriting = true;

		if (this.qtnRelatedProgramme == true) {
			this.getSkillResponse(currentMessage).subscribe((rasaMessage: any) => {
				//Rasa send empty data
				if (this.rasaNotUnderstandingMsg(rasaMessage)) {
					this.chatbotCommunication.next("newmessage");
					this.isWriting = false;
					return;
				}
				rasaMessage.forEach(item => {
					let skillPercentage = parseInt(item.text.substring(item.text.lastIndexOf(":") + 1, item.text.indexOf("%")));

					if (skillPercentage > 10) {
						let relatedSkill = item.text.substring(2, item.text.indexOf(":"));

						let skillsFiltered = this.moodleService.badges.filter(item => {
							return item.name.startsWith(relatedSkill.substring(0, 4));
						});

						if (typeof skillsFiltered[0] != "undefined") {
							skillsDisplayed.push({ result: item.text, skillId: skillsFiltered[0].id });
						}
					}
				});

				setTimeout(() => {
					this.messages.push({
						_id: Math.random().toString(36).substring(2, 15),
						message: skillsDisplayed,
						role: "ECBS",
						skillsMappingEnable: true,
						currentUniv: typeof currentUniv != "undefined" ? currentUniv : undefined,
						message_date: new Date()
					});
					this.isWriting = false;
					this.chatbotCommunication.next("newmessage");
				}, delay);

				//  this.getUnivProgrammsResponse('Hello').subscribe(() => {})
				this.skillChosen = true;
			});
		} else {
			this.getUnivProgrammsResponse(currentMessage).subscribe((rasaMessage: any) => {
				const [rasaMessageObject] = rasaMessage;

				if (rasaMessage.length > 0) {
					if (Array.isArray(rasaMessage)) {
						if (rasaMessage[0].text.includes("Hello")) {
							this.messages.push({
								_id: Math.random().toString(36).substring(2, 15),
								message: rasaMessageObject.text,
								role: "ECBS",
								msgWelcome: true,
								message_date: new Date()
							});

							this.isWriting = false;
							return;
						}
					} else {
						if (rasaMessage.text.includes("Hello")) {
							this.messages.push({
								_id: Math.random().toString(36).substring(2, 15),
								message: rasaMessageObject.text,
								role: "ECBS",
								msgWelcome: true,
								message_date: new Date()
							});

							this.isWriting = false;
							return;
						}
					}
				}

				//Rasa send empty data
				if (this.rasaNotUnderstandingMsg(rasaMessage)) {
					this.chatbotCommunication.next("newmessage");
					this.isWriting = false;
					return;
				}

				this.moodleService.allUniversities.forEach(univ => {
					splittedRasaData = rasaMessageObject.text.split(" ");

					splittedRasaData.find(rasaData => {
						if (rasaData.includes(",")) {
							let commaSplit = rasaData.split(",");
							splittedRasaData = splittedRasaData.concat(commaSplit);
						}
					});

					splittedRasaData.find(rasaData => {
						if (rasaData.toLocaleLowerCase() == univ.shortname.toLocaleLowerCase() && !splittedRasaData.includes("choose")) {
							currentUniv = univ;
						}
					});
				});

				setTimeout(() => {
					if (rasaMessageObject.text.includes("choose")) {
						this.qtnRelatedProgramme = true;
						this.universityDeviceChosen = true;
					}
					this.messages.push({
						_id: Math.random().toString(36).substring(2, 15),
						message: rasaMessageObject.text,
						role: "ECBS",
						currentUniv: typeof currentUniv != "undefined" ? currentUniv : undefined,
						id: currentUniv ? currentUniv.id : null,
						message_date: new Date()
					});
					this.isWriting = false;
					this.chatbotCommunication.next("newmessage");
				}, delay);
			});
		}
	}

	rasaNotUnderstandingMsg(rasaMessage): boolean {
		if (rasaMessage.length == 0) {
			this.messages.push({
				_id: Math.random().toString(36).substring(2, 15),
				message: "I'm sorry, I didn't understand you.",
				role: "ECBS",
				message_date: new Date()
			});
			return true;
		}

		return false;
	}

	getSkillResponse(message) {
		let body = {
			sender: this.connectSid,
			message
		};
		let header = new HttpHeaders({ "Content-type": "application/json charset=utf-8" });
		return this.http.post(this.SKILL_MODEL, body, { headers: header });
	}

	getUnivProgrammsResponse(message) {
		let body = {
			sender: this.connectSid,
			message
		};
		let header = new HttpHeaders({ "Content-type": "application/json" });
		return this.http.post(this.UNIVERSITYY_PROGRAMMS_MODEL, body, { headers: header });
	}

	getIntentResponse(message) {
		let body = {
			sender: this.connectSid,
			message
		};
		let header = new HttpHeaders({ "Content-type": "application/json" });
		return this.http.post(this.BASE_URL, body, { headers: header });
	}
}
