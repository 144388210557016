import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { User } from '../models/user';
import {  Router } from '@angular/router';
import { Badge } from '../models/badge';
import { UserCredential } from '../models/UserCredential';
import { Subject } from 'rxjs';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';

@Injectable({
  providedIn: 'root'
})

export class RealAuthService implements AuthService {
  user: User;
  router: Router;
  public signInfo = new Subject<any>();
  userCredential: UserCredential;
  badge: Badge;


  constructor(public openNativeSettings : OpenNativeSettings) {
    if (localStorage.getItem('user') == undefined) {
      this.user = new User();
      this.user.auth = new UserCredential();
      this.user.auth.connected = false;
      this.saveUser(this.user); // user object.

    } else if (localStorage.getItem('user') != undefined) {
      this.user = this.loadUser('user');  // is there is a 'user', it load the infos in a new
    }
  }

  /**
	 * Open tracking permission parameters for IOS
	 */

	openIosTrackingPermission() {
		this.openNativeSettings.open("tracking").then(trackingPermission => {
			console.log("trackingPermission", trackingPermission);
		});
	}

  /**
   * SID
   */

   public returnHash() {
      const abc = "abcdefghijklmnopqrstuvwxyz1234567890".split("");
      let token = "";
      for (let i = 0; i < 32; i++) {
        token += abc[Math.floor(Math.random() * abc.length)];
      }
      return token; // Will return a 32 bit "hash"
    }

  public saveUser(user: User): any {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public loadUser(key): any {
    return JSON.parse(localStorage.getItem(key));
  }

  public setUser(user: User) {
    throw new Error('Method not implemented.');
  }

 

   /** LOCALSTORAGE */

   storeLocalStorValue(key: string, value) {
    return localStorage.setItem(key, value);
  }

  getLocalStorValue(key){
    return localStorage.getItem(key);
  }

  removeLocalStorKey(key) {
    return localStorage.removeItem(key);
  }



   public get userConnected(): boolean {
    const userConnected = JSON.parse(localStorage.getItem('user'));
    return userConnected.auth.connected ? true : false;
  }

}
