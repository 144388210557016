import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { Platform, AlertController, ModalController, Events } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { timer } from "rxjs";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { SkillService } from "./services/skill.service";
import { AuthService } from "./services/auth.service";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { ModalNotificationComponent } from "./components/modal-notification/modal-notification.component";
import { OneSignalService } from "./services/onesignal.service";
import { Router, RouterOutlet } from "@angular/router";
import { RealMoodleService } from "./services/real-moodle.service";
import { RasaChatbotService } from "./services/rasa-chatbot.service";
import { NavigationBar } from "@ionic-native/navigation-bar/ngx";

declare var window: {
	plugins : any;
	cordova: any;
}

@Component({
	selector: "app-root",
	templateUrl: "app.component.html"
})
export class AppComponent {
	public appPages = [
		{
			title: "Home",
			url: "/tabs",
			icon: "home"
		},
		{
			title: "Universities",
			url: "/tabs/list",
			icon: "school"
		},
		{
			title: "Map",
			url: "/tabs/europa-map",
			icon: "map"
		},
		{
			title: "Notification",
			modal: true,
			url: "/tabs/europa-map",
			icon: "notifications"
		},
		{
			title: "Logout",
			url: "/tabs/login",
			icon: "log-out"
		}
	];

	// Show SplashScreen
	showSplash = true;
	screenSubsciption: any;
	isBrowser: boolean;
	menuSide: string;

	constructor(
		public platform: Platform,
		public splashScreen: SplashScreen,
		public statusBar: StatusBar,
		public location: Location,
		public screenOrientation: ScreenOrientation,
		public skillService: SkillService,
		public moodleService: RealMoodleService,
		public authService: AuthService,
		public oneSignal: OneSignal,
		public alertController: AlertController,
		public events: Events,
		public rasaChatbotService: RasaChatbotService,
		public router: Router,
		public modalController: ModalController,
		public oneSignalService: OneSignalService,
		private navigationBar: NavigationBar
	) {
		this.moodleService.getAllUniversities();
		this.moodleService.getAllSkills();
		this.moodleService.getDeviceById(0);
		this.moodleService.getUseCases();
		this.moodleService.getStrings("");

		if (this.skillService.user.auth.connected) {
			this.moodleService.getSkillsAdded(this.skillService.user.moodleToken).subscribe(skill => {
				let allSkillsAdded;
				allSkillsAdded = skill;
				allSkillsAdded.forEach(item => {
					this.skillService.addMdlUserSkill(item);
				});
			});

			this.moodleService
				.getUserInfos("username", this.skillService.user.auth.username, this.moodleService.userToken)
				.subscribe((userInfo: any) => {
					this.skillService.user.auth.id = userInfo[0].id;
					this.skillService.user.auth.email = userInfo[0].email;
					this.authService.saveUser(this.skillService.user);
				});

			// Update user course course assignment id, used to send a certificate demand and user courses
			this.moodleService.getUserCourses(this.skillService.user.auth.id).subscribe((courses: any) => {
				if (courses && courses.length > 0) {
					this.moodleService.getAssignmentId(courses[0].id).subscribe((data: any) => {
						if (data && data.courses.length > 0) {
							this.skillService.user.course_assignment_id = data.courses[0].assignments[0].id;
						}

						this.skillService.user.enroled_courses_id.length = 0;
						courses.forEach(course => {
							this.skillService.user.enroled_courses_id.push(course.id);
						});
						this.authService.saveUser(this.skillService.user);
					});
				}
			});

			this.moodleService.universityChoice(this.skillService.user.enroled_courses_id[0]).subscribe(data => {
				this.skillService.user.universityChoice = data[0];
				this.authService.saveUser(this.skillService.user);
			});
		}

		console.log('initialized before');
		this.initializeApp();
		this.backButtonEvent();
		this.observeScreenOrientation();
		this.detectPlatform();

		this.authService.signInfo.subscribe(data => {
			if (data === "user:loggedout") {
				const menuNotification = document.querySelector(".menu-notification") as HTMLElement;
				const menuLogout = document.querySelector(".menu-logout") as HTMLElement;
				menuNotification.style.display = "none";

				if (menuLogout) {
					menuLogout.style.display = "none";
				}
			} else if (data === "user:loggedin") {
				const menuNotification = document.querySelector(".menu-notification") as HTMLElement;
				const menuLogout = document.querySelector(".menu-logout") as HTMLElement;
				menuNotification.style.display = "block";
				if (menuLogout) {
					menuLogout.style.display = "block";
				}
			}
		});
	}

	/** USE CASES EXPORT SKILL */
	async presentModal(skillName) {
		const modal = await this.modalController.create({
			component: ModalNotificationComponent
		});
		return await modal.present();
	}

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
	}

	initializeApp() {
		console.log('platform ready not yet launched');
		this.platform.ready().then(async () => {
		
			if (this.platform.is("cordova")) {
				this.statusBar.styleDefault();
				this.statusBar.overlaysWebView(false);
				this.statusBar.backgroundColorByHexString("#ffd810");

				if(this.platform.is("ios")){
					const tracking  = await this.trackingPermissionsIOS();
					console.error("permission tracking,",tracking)
					if(!tracking){
						if(this.authService.userConnected){
							this.skillService.logout();
							this.router.navigateByUrl("home");
						}
						this.skillService.trackingPermission = false;
					}else{
						this.skillService.trackingPermission = true;
					}
				}else{
					this.skillService.trackingPermission = true;
				}

				this.oneSignal.startInit("e1efccc9-50a3-4e95-8dc9-780e7bfadd61", "496497768289");
				this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
	
				this.oneSignal.handleNotificationOpened().subscribe(data => {
					const additionalDataa = data.notification.payload.additionalData;
					this.router.navigateByUrl("/tabs/profile");
				});
	
				this.oneSignal.endInit();
	
			}else {
				this.skillService.trackingPermission = true;
			}
			this.platform.pause.subscribe(() => {
				console.log("****UserdashboardPage PAUSED****"); //
			});

			// splash timer
			console.log('here initialized');
			this.splashScreen.hide();
			this.showSplash = false
			timer(1500).subscribe(() => (this.showSplash = false));
		});
	}

	trackingPermissionsIOS(): Promise<boolean> {
		return new Promise(resolve => {
			if (window.cordova) {
				console.log("trying to request permission ");

				const idfa = window.cordova.plugins.idfa;
				// {"trackingPermission":2,"idfa":"00000000-0000-0000-0000-000000000000","trackingLimited":true}

				idfa.requestPermission()
					.then(permission => {
						if (permission !== 3) {
							// Permission tracking refused
							resolve(false);
						} else {
							// Permission accepted
							resolve(true);
						}
					})
					.catch(err => {
						console.error("err", err);
						resolve(true);
					});
			} else {
				resolve(false);
			}
		});
	}

	backButtonEvent() {
		this.platform.backButton.subscribe(() => {
			this.location.back();
		});
	}

	// detect orientation changes
	observeScreenOrientation() {
		this.screenSubsciption = this.screenOrientation.onChange().subscribe(() => {
			console.log(`Screen Orientation Changed to ${this.screenOrientation.type}`);
		});
	}

	detectPlatform() {
		console.log(`Platform = ${this.platform.platforms()}`);
	}

	ionViewWillLeave() {
		this.screenSubsciption.unsubscribe();
	}
}
