import { Injectable, NgModule } from "@angular/core";
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthService } from "./services/auth.service";
import { SkillService } from "./services/skill.service";
import { SharedModule } from "./shared/shared.module";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { RealSkillService } from "./services/real-skill.service";
import { RealMoodleService } from "./services/real-moodle.service";
import { SkillsMappingComponent } from "./components/skills-mapping/skills-mapping.component";
import { Clipboard } from "@ionic-native/clipboard/ngx";
import { File } from "@ionic-native/file/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { OverlayModule } from "@angular/cdk/overlay";
import { ModalNotificationComponent } from "./components/modal-notification/modal-notification.component";
import { SlickService } from "./services/slick.service";
import { FilesService } from "./services/files.service";
import { SavedFilesService } from "./services/saved-files.service";
import { RealAuthService } from "./services/real-auth.service";
import * as Hammer from "hammerjs";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { NavigationBar } from "@ionic-native/navigation-bar/ngx";
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
    buildHammer(element:HTMLElement){
		let mc = new Hammer(element, {
			touchAction: "pan-y"
		});
		return mc;
	}

	overrides = {
		pan: {
			direction: Hammer.DIRECTION_All,
			
		}
	};
}

@NgModule({
	declarations: [AppComponent],

	entryComponents: [SkillsMappingComponent, ModalNotificationComponent, PrivacyPolicyComponent],

	imports: [
		BrowserModule,
		HttpClientModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		SharedModule,
		FormsModule,
		BrowserAnimationsModule,
		OverlayModule
	],

	providers: [
		StatusBar,
		SplashScreen,
		OneSignal,
		SavedFilesService,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: AuthService, useClass: RealAuthService },
		{ provide: SkillService, useClass: RealSkillService },
		{ provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
		SlickService,
		ScreenOrientation,
		OpenNativeSettings,
		FilesService,
		Clipboard,
		File,
        FileOpener,
        NavigationBar
	],

	bootstrap: [AppComponent]
})
export class AppModule {}
