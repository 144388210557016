import { Component, OnInit, Input } from "@angular/core";
import { OneSignalService } from "src/app/services/onesignal.service";
import { ModalController } from "@ionic/angular";
import { SkillService } from "src/app/services/skill.service";

@Component({
	selector: "app-modal-notification",
	templateUrl: "./modal-notification.component.html",
	styleUrls: ["./modal-notification.component.scss"]
})
export class ModalNotificationComponent {
	@Input() public isSubscribed: boolean;
	@Input() public oneSignalService: any;

	constructor(public skillService: SkillService, public modalController: ModalController) {}

	subscription() {
		this.oneSignalService.setSkillContext(this.skillService);
		this.oneSignalService.subscribeCurrentUser();
	}

	unsubscribe() {
		this.oneSignalService.setSkillContext(this.skillService);
		this.oneSignalService.unSubscribeCurrentUser();
	}

	dismiss() {
		this.modalController.dismiss();
	}
}
