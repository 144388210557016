import { Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SlickService {
  public slideConfigBadges: any;
  public slideConfigSkillExplorer: any;
  constructor() {
    this.slideConfigBadges = {
      arrow: false,
      dots: false,
      slidesPerRow: 3,
      nextArrow: '<img src="assets/icon/picto-fleche_grise_slider.svg" style="width:50px" class="img_next_badges">',
      prevArrow: '<img src="assets/icon/picto-fleche_grise_slider.svg" style="width:50px" class="img_prev_badges">',
      rows: 2,
      infinite: false,
      swipe: false
    };


    this.slideConfigSkillExplorer = {
      arrow: false,
      dots: false,
      slidesPerRow: 2,
      nextArrow:  '<img src="assets/icon/picto-fleche_grise_slider.svg" style="width:50px" class="img_next_explorer">',
      prevArrow: '<img src="assets/icon/picto-fleche_grise_slider.svg" style="width:50px" class="img_prev_explorer">',
      rows: 3,
      infinite: false,
      swipe: false
    };
  }


  /** Not display slick previous button at start */
  slickInit(classNamePrevBtn) {
      const prevBtn = <HTMLElement>document.querySelector(`.${classNamePrevBtn}`); 
      prevBtn.style.display = 'none';
  }

    /** Manage slick prev and next buttons */
  afterChange(e, classNameNextBtn, classNamePrevBtn) {
    let nextBtn;
    let prevBtn;

    // IF SLIDE POSITION END NOT SHOW NEXT BTN

    if (e.currentSlide == e.slick.slideCount - 1) {
      nextBtn =  <HTMLElement> document.querySelector( `.${classNameNextBtn}`); 
      nextBtn.style.display = 'none';
    } else {
      prevBtn =  <HTMLElement> document.querySelector(`.${classNameNextBtn}`);
      prevBtn.style.display = 'block';
    }

    // IF SLIDE POSITION START NOT DISPLAY BTN PREV
    if (e.currentSlide == 0) {
      prevBtn =  <HTMLElement> document.querySelector(`.${classNamePrevBtn}`); 
      prevBtn.style.display = 'none';
    } else {
      prevBtn =  <HTMLElement> document.querySelector(`.${classNamePrevBtn}`); 
      prevBtn.style.display = 'block';
    }
  }
}
