import { NgModule } from '@angular/core';
import {
	PreloadAllModules,
	RouterModule,
	Routes,
} from '@angular/router';
import { GdprGuardService } from './services/gdpr-guard.service';

const routes: Routes = [
	

	{ path: 'home', loadChildren: './page/home/home.module#HomePageModule' },
	{ path: '', loadChildren: './page/home/home.module#HomePageModule' },
	{
		path: '',
		redirectTo: '',
		pathMatch: 'full'
	},
	{
		path: 'tabs',
		loadChildren: './page/tabs/tabs.module#TabsPageModule',
	}

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { 
      preloadingStrategy: PreloadAllModules 
    })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
