import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SkillService } from 'src/app/services/skill.service';
import { Router } from '@angular/router';
import { Skill } from 'src/app/models/skill';
import { AuthService } from 'src/app/services/auth.service';
import { Platform, ModalController, NavParams } from '@ionic/angular';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { User } from 'src/app/models/user';
import { RealMoodleService } from 'src/app/services/real-moodle.service';

declare var ClipboardJS;
@Component({
  selector: 'app-skills-mapping',
  templateUrl: './skills-mapping.component.html',
  styleUrls: ['./skills-mapping.component.scss'],
})
export class SkillsMappingComponent implements OnInit {
  desktop = false;
  certificationArr = [];
  certificationStringify = [];
  user: User;
  copied = false;
  stopCopy = false;
  mobileweb = false;
  clipboardInst: any;
  bothContainsElements: any = false;
  userBadges: any;
  allSkills = new Array();


  constructor(public skillService: SkillService, public navParams: NavParams, public moodleService: RealMoodleService, 
              public modalController: ModalController, public authService: AuthService, public router: Router,
              public platform: Platform, public clipboard: Clipboard) {
              this.user = this.skillService.user;
              this.userBadges = navParams.get("userBadges");
  }

  ngOnInit() {

    if (this.platform.is('desktop')) {
      this.desktop = true;
    }

    if (this.platform.is('mobileweb')) {
      this.mobileweb = true;
    }


    if (this.platform.is('desktop')) {
      this.clipboardInst = new ClipboardJS('#btnCopy');
    }

    // User connected
    if (this.skillService.user.auth.connected) {

        if (typeof this.userBadges != "undefined") {
          // If user has acquired badge(s)
          if (this.userBadges.length > 0) {
            this.userBadges =  this.userBadges.map(badge => {
              return this.moodleService.badges.find(a => a.id === badge.badgeid);
            });
          }
        }

        this.bothContainsElements = false;
        // If user has added skills in cart
        if (this.skillService.moodleSkillsToggle._mdl_skills.length > 0) {
          if (typeof this.userBadges != 'undefined') {
              if (this.userBadges.length > 0) {
                // User has awarded badge(s) and added skills in cart
                this.bothContainsElements = true;
            }
          } else {
            this.bothContainsElements = false;
          }
          this.skillService.moodleSkillsToggle._mdl_skills.forEach(item => {
            if (this.bothContainsElements) {
              this.userBadges.forEach(element => {
                this.allSkills.push(item, element);
            });
            } else {
              this.allSkills.push(item);
            }
          });
          // Remove duplicate values and keep commun array
          this.allSkills = this.allSkills.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.id === item.id
          ))
        );
        } else {
          this.userBadges.forEach(element => {
            this.allSkills.push(element);
          });
        }
    }
  }

  createCopyItems(arr: Array<any>) {
    const elementToCopy = new Array();
    arr.forEach((data: any) => {
    const regex = /(<([^>]+)>)/ig;
    const description  = data.description.replace(regex, " ");
    elementToCopy.push('- ' + data.name + ' : ' + description );
  });

    return elementToCopy;
  }


/**
 *  Copy skills mapping for Desktop
 *
 * @param e Angular event
 */

  copySkillsMapping(e) {
    const self = this;
    if (this.mobileweb) {
      let arrayOfElToCopy ;
      let elementToCopy = '' ;

      if (this.skillService.user.auth.connected) {
        if (typeof this.userBadges != "undefined" && !this.bothContainsElements) {
          if ((typeof this.skillService.moodleSkillsToggle._mdl_skills != 'undefined' && this.userBadges.length  === 0)) {
            arrayOfElToCopy =  this.createCopyItems(this.skillService.moodleSkillsToggle._mdl_skills);
          } else {
            arrayOfElToCopy =  this.createCopyItems(this.userBadges);
          }
      } if (this.bothContainsElements) {
        arrayOfElToCopy =  this.createCopyItems(this.allSkills);
      }
      } else {
        arrayOfElToCopy = this.createCopyItems( this.skillService.currentCertification._skills);
      }
      const textArea = document.createElement("textarea");
      arrayOfElToCopy.forEach(item => {
        elementToCopy += item;
      });

      textArea.value = elementToCopy;
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";
      textArea.style.opacity = "0";
      textArea.select();
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('Copy');
      textArea.parentNode.removeChild(textArea);

      self.moodleService.getStrings('toast:skills_copied_btn').then(data => {
        e.srcElement.innerHTML = data;
      });
  } else {
    this.clipboardInst.on('success', function(e) {
          e.clearSelection();
          if (!self.copied && !self.stopCopy) {
            self.moodleService.getStrings('toast:skills_copied').then(data => {
              self.skillService.presentToast(data);
            });
          } else if (!self.stopCopy) {
            self.moodleService.getStrings('toast:skills_already_copied').then(data => {
              self.skillService.presentToast(data);
            });
            self.stopCopy = true;
          }

          self.moodleService.getStrings('toast:skills_copied_btn').then(data => {
            e.trigger.textContent = data;
          });
          self.copied = true;
      });
  }
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  /**
   * Copy skills mapping for mobile and tablet
   */

  copyMobile() {
   this.certificationArr = new Array();
   if (this.skillService.user.auth.connected) {
    if (typeof this.userBadges != "undefined" && !this.bothContainsElements) {

      if ((typeof this.skillService.moodleSkillsToggle._mdl_skills != 'undefined' && this.userBadges.length  === 0)) {
        this.certificationArr.push(this.skillService.moodleSkillsToggle._mdl_skills);
      } else {
        this.certificationArr.push(this.userBadges);
      }
    }
    if (this.bothContainsElements) {
      this.certificationArr.push(this.allSkills);
    }
    } else {
      this.certificationArr.push(this.skillService.currentCertification.skills);
    }
   this.certificationArr.forEach(certification => {
    certification.forEach(element => {
      this.certificationStringify.push(`${element.name} :  ${element.description}`);
    });
   });
   this.clipboard.copy(this.certificationStringify.toString().replace(/[<]br[^>]*[>]/gi, "")).then(item => {
    this.moodleService.getStrings('toast:skills_copied_btn').then((data: string) => {
      document.querySelector('.copyMobile').innerHTML = data;
    });
   });

   this.certificationStringify = [];
  }

  public goToSkill(selectedSkill: Skill) {
    this.router.navigateByUrl('/tabs/selected-skill/' + selectedSkill.id);
  }

}
