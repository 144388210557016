import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export abstract class AuthService {
  public abstract user: User;
  public abstract signInfo = new Subject<any>();
  public abstract setUser(user: User): any;
  public abstract saveUser(user: User);
  public abstract loadUser(key: string);
  public abstract returnHash();
  public abstract storeLocalStorValue(key, value);
  public abstract getLocalStorValue(key);
  public abstract removeLocalStorKey(key);
  public abstract get userConnected(): boolean;
  public abstract openIosTrackingPermission();
}
