import { University } from './university';
import { Badge } from './badge';
import { UserCredential } from './UserCredential';


export class User {
    public auth: UserCredential;
    public universities: University[] = new Array<University>();
    public moodleToken: string;
    public universityId: number;
    public enroled_courses_id: Array<any> = new Array();
    public course_assignment_id: number;
    public badges: Badge[] = new Array<Badge>();
    public universityChoice: string;

}
