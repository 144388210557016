import { Injectable } from "@angular/core";
import { SkillService } from "./skill.service";
import { AuthService } from "./auth.service";
import { User } from "../models/user";
import { take } from "rxjs/operators";
import { Platform, ToastController, ModalController } from "@ionic/angular";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { ModalNotificationComponent } from "../components/modal-notification/modal-notification.component";
import { Router } from "@angular/router";
import { RealMoodleService } from "./real-moodle.service";

@Injectable({
	providedIn: "root"
})
export class OneSignalService {
	public user: User;
	public alreadyInit = false;
	public isSubscribedMT = false;
	public globalOneSignalDesktop: any = "";
	public isSubscribed = false;
	public subscriptionEnableMT = false;
	public permissionCalled = true;
	public alreadyCheckedState = false;
	constructor(
		public skillService: SkillService,
		public router: Router,
		public authService: AuthService,
		public modalContoller: ModalController,
		public oneSignal: OneSignal,
		public toastController: ToastController,
		public moodleService: RealMoodleService,
		public pfm: Platform
	) {}

	setSkillContext(skillContext) {
		this.skillService = skillContext;
	}

	init() {
		const oneSignal = window["OneSignal"] || [];

		this.user = this.authService.loadUser("user");
		if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
			let univName;
			const university = JSON.parse(localStorage.getItem("item"));
			if (university) {
				univName = university.name;
			}

			if (!this.alreadyInit) {
				this.alreadyInit = true;
				oneSignal.push([
					"init",
					{
						appId: "e1efccc9-50a3-4e95-8dc9-780e7bfadd61",
						safari_web_id: "web.onesignal.auto.4058b815-86ea-44b3-9131-733c406f5a63",
						autoRegister: false,
						notifyButton: {
							enable: true,
							displayPredicate: function (data) {
								return oneSignal.isPushNotificationsEnabled().then(function (isPushEnabled) {
									return !isPushEnabled;
								});
							}
						},
						notificationClickHandlerMatch: "origin",
						notificationClickHandlerAction: "focus"
					}
				]);
			}
			this.globalOneSignalDesktop = oneSignal;
			/**
			 * Add an event listener when the link is clicked
			 *
			 */

			oneSignal.push([
				"addListenerForNotificationOpened",
				notificationData => {
					this.router.navigateByUrl("/tabs/profile");
				}
			]);

			if (this.pfm.is("cordova")) {
				this.oneSignal.handleNotificationOpened().subscribe(() => {
					this.router.navigateByUrl("/tabs/profile");
				});
			}

			if (typeof oneSignal === "function") {
				oneSignal.isPushNotificationsEnabled(function (isEnabled) {
					const domElement = <HTMLElement>document.querySelector("#onesignal-bell-container");
					if (domElement) {
						domElement.style.display = "none";
					}
				});
				oneSignal.push(function () {
					oneSignal.push(["registerForPushNotifications"]);
				});
			}
		}
	}

	checkCurrentUserState(moodle) {
		this.setMoodle(moodle);
		this.init();
		if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
			if (this.isFunction()) {
				this.checkState(this.globalOneSignalDesktop);
			}
		} else {
			this.checkState(this.oneSignal);
		}
	}

	/**
	 * Disconnect from personal account
	 *
	 */

	disconnect() {
		if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
			if (this.isFunction()) {
				this.globalOneSignalDesktop.setSubscription(false);
				this.manageTags(this.globalOneSignalDesktop, "delete");
			}
		} else {
			this.oneSignal.setSubscription(false);
			this.manageTags(this.oneSignal, "delete");
		}
	}

	subscribeCurrentUser() {
		if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
			if (this.isFunction()) {
				this.subscribeUser(this.globalOneSignalDesktop);
			}
		} else {
			this.subscribeUser(this.oneSignal);
		}
	}

	unSubscribeCurrentUser() {
		if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
			if (this.isFunction()) {
				this.unSubscribeUser(this.globalOneSignalDesktop);
			}
		} else {
			this.unSubscribeUser(this.oneSignal);
		}
	}

	/**
	 * Verify if One Signal exist in browser
	 */

	isFunction(): boolean {
		if (typeof this.globalOneSignalDesktop.setSubscription === "function") {
			return true;
		}
		return false;
	}

	public setMoodle(moodleService) {
		this.moodleService = moodleService;
	}

	/**
	 * Verify if it's the first time that the connect on his account
	 *
	 * @param oneSignal One signal object
	 */

	checkState(oneSignal) {
		this.init();
		this.moodleService
			.getSubscribedUsers(this.user.auth.id)
			.pipe(take(1))
			.subscribe((getAllSubUsers: any) => {
				if (getAllSubUsers.length === 0) {
					this.isSubscribed = false;
					if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
						try {
							Notification.requestPermission().then(permission => this.firstTimeConnection(oneSignal, permission));
						} catch (error) {
							if (error instanceof TypeError) {
								Notification.requestPermission(permission => {
									this.firstTimeConnection(oneSignal, permission);
								});
							} else {
								throw error;
							}
						}
					} else {
						this.notYetNotificationsEnabled(this.oneSignal, "mobTab");
					}
				} else {
					getAllSubUsers.forEach((user: any) => {
						if (user.subscribed == 0) {
							this.isSubscribed = true;
							oneSignal.setSubscription(true);
							this.manageTags(oneSignal, "create");
						} else {
							this.isSubscribed = false;
						}
					});
				}
			});
	}

	firstTimeConnection(oneSignal, permission) {
		if (permission == "granted") {
			if (this.permissionCalled) {
				this.displayNotificationPopUp();
				this.notYetNotificationsEnabled(oneSignal, null);
				this.permissionCalled = false;
			}
		}
	}

	/**
	 * Display popup for subscribe or unsubscribe to notification
	 *
	 */

	async displayNotificationPopUp() {
		if (this.skillService.trackingPermission) {
			if (this.pfm.is("desktop") || this.pfm.is("mobileweb")) {
				if (typeof this.globalOneSignalDesktop == "function") {
					const modal = await this.modalContoller.create({
						component: ModalNotificationComponent,
						componentProps: { isSubscribed: this.isSubscribed, oneSignalService: this }
					});
					return await modal.present();
				} else {
					this.moodleService.getStrings("toast:notification_blocking").then((data: any) => {
						this.presentToast(data);
					});
				}
			} else {
				const modal = await this.modalContoller.create({
					component: ModalNotificationComponent,
					componentProps: { isSubscribed: this.isSubscribed, oneSignalService: this }
				});
				return await modal.present();
			}
		}
	}

	async presentToast(message: string, durationTime?) {
		const toast = await this.toastController.create({
			message,
			duration: typeof durationTime != "undefined" ? durationTime : 5000,
			color: "favorite",
			cssClass: this.pfm.is("desktop") ? "toast-custom-class" : ""
		});
		toast.present();
	}

	/**
	 * First time connection
	 *
	 * @param OneSignal One signal object
	 * @param data Mobile element
	 */
	notYetNotificationsEnabled(oneSignal, data) {
		if (data == "mobTab") {
			this.displayNotificationPopUp();
		}
		oneSignal.setSubscription(false);
		this.manageTags(oneSignal, "delete");
		this.isSubscribed = false;
		this.manageDatabaseVerification(false);
	}

	/**
	 * Subscription
	 *
	 * @param oneSignal One signal object
	 */

	subscribeUser(oneSignal) {
		oneSignal.setSubscription(true);
		this.manageTags(oneSignal, "create");
		this.manageDatabaseVerification(true);
		this.isSubscribed = true;
	}

	/**
	 * Unsubscription
	 *
	 * @param oneSignal One signal object
	 */

	unSubscribeUser(oneSignal) {
		oneSignal.setSubscription(false);
		this.manageTags(oneSignal, "delete");
		this.manageDatabaseVerification(false);
		this.isSubscribed = false;
	}

	/**
	 * Add and remove one signal tags
	 *
	 * @param OneSignal One signal object
	 * @param data Action
	 */

	manageTags(oneSignal, data) {
		if (data == "delete") {
			oneSignal.deleteTags(["user_id", "university"]);
		} else {
			this.user = this.skillService.user;
			let university;
			let univName;
			this.moodleService.getAllUniversities().then((universities: any) => {
				university = universities.find(univ => {
					return univ.id == this.user.enroled_courses_id[0];
				});
				if (university) {
					univName = university.name;
				}
				oneSignal.sendTags({
					user_id: this.user.auth.id,
					university: univName
				});
			});
		}
	}

	/**
	 * Save status in DB
	 *
	 * @param subscriptionStatus Indicate status, subscribed or not subscribed
	 */

	manageDatabaseVerification(subscriptionStatus: boolean) {
		this.moodleService.manageOneSignalUserId(this.user.auth.id, subscriptionStatus).subscribe(
			data => {},
			err => {
				console.log("err", err);
			}
		);
	}
}
