import { Injectable } from "@angular/core";
import { RealMoodleService } from "./real-moodle.service";
import { SkillService } from "./skill.service";
import { FilesService } from "./files.service";

@Injectable({
	providedIn: "root"
})
export class SavedFilesService {
	public dbObject;
	public currentSavedDemand: any;
	public skillsAdd: any;

	constructor(public moodleService: RealMoodleService, public skillService: SkillService, public filesService: FilesService) {}

	/**
	 * Get item id of saved files
	 *
	 * @param existingData All user certificate demands
	 */

	getFilesItemId(existingData: Array<any>) {
		for (const i in existingData) {
			if (existingData[i]) {
				existingData[i].find(item => {
					if (typeof item.itemid == "number") {
						this.moodleService.saveditemid = item.itemid;
					}
				});
			}
		}
	}

	/**
	 * Get user demand
	 *
	 * @param existingData All user certificate demands
	 * @param skillsAssociated Current skills that can be associated with evidence
	 */

	getSavedFiles(existingData, skillsAssociated) {
		if (existingData) {
			this.getFilesItemId(existingData);
			const demandKeys = Object.keys(existingData);
			if (demandKeys.length > 0) {
				const draftArray = new Array();

				// Only access the user's demand where the demand status is submitted or renew
				for (const index in existingData) {
					if (existingData[index]) {
						existingData[index].find(item => {
							if (typeof item.itemid === "number") {
								this.moodleService.saveditemid = item.itemid;
							}

							if (item.status === "submitted" || item.status === "renew") {
								draftArray.push(item);
							}
						});
					}
				}

				const onlinetext = this.moodleService.formatCertificateDemand(draftArray);

				let allDemandsArr = new Array();

				for (const i in onlinetext) {
					if (existingData[i]) {
						for (const j in onlinetext[i]) {
							if (onlinetext[i][j]) {
								allDemandsArr.push(onlinetext[i][j]);
							}
						}
					}
				}

				// Remove duplicate values
				allDemandsArr = allDemandsArr.filter((data, index, self) => index === self.findIndex(t => t.id === data.id));

				if (allDemandsArr.length > 0) {
					// Concat user chosen associated skills with the current associated skills
					allDemandsArr.forEach(data => {
						data.skillsAssociated = data.skillsAssociated.filter(el => {
							return skillsAssociated.some(f => {
								return f.skill.id === el.skill.id;
							});
						});

						// Create the file model object
						this.dbObject = {
							id: data.id,
							message: data.comment,
							skillsAssociated: data.skillsAssociated,
							skillsAssociatedChosen: data.skillsAssociatedChosen,
							name: data.name,
							urlValues: data.urlValues,
							message_length: data.message_length
						};

						// Create file object
						if (!this.filesService.alreadyVerified) {
							this.skillService.addUserFiles(this.dbObject, false);
						}
					});
				}
				this.filesService.alreadyVerified = true;
			}
		}
	}
}
