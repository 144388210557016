import { NgModule} from '@angular/core';
import { TruncatPipePipe } from '../services/truncat-pipe.pipe';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SkillsMappingComponent } from '../components/skills-mapping/skills-mapping.component';
import { TooltipDirective } from '../components/tooltip.directive';
import { ModalNotificationComponent } from '../components/modal-notification/modal-notification.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import { ChatbotComponent } from '../components/chatbot/chatbot.component';
import { PercentCircleComponent } from '../components/percent-circle/percent-circle.component';
import { DownloadBadgesComponent } from '../components/download-badges/download-badges.component';
import { DesktopFooterComponent } from '../components/desktop-footer/desktop-footer.component';
import { PrivacyPolicyComponent } from '../components/privacy-policy/privacy-policy.component';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion'


@NgModule({
  declarations: [
    TruncatPipePipe,
    ChatbotComponent,
    SkillsMappingComponent,
    ModalNotificationComponent,
    PercentCircleComponent,
    ChatbotComponent,
    TooltipDirective,
    DownloadBadgesComponent,
    DesktopFooterComponent,
    PrivacyPolicyComponent,
  ],


  exports: [
    TruncatPipePipe,
    PercentCircleComponent,
    MatRadioModule,
    ChatbotComponent,
    DownloadBadgesComponent,
    SkillsMappingComponent,
    TooltipDirective,
    MatProgressSpinnerModule,
    MatExpansionModule,
    DesktopFooterComponent,
    PrivacyPolicyComponent,
  ],
  imports: [IonicModule, CommonModule, FormsModule,MatRadioModule, MatProgressSpinnerModule,MatExpansionModule]  
})

export class SharedModule { }